import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { MainMenu } from '@components';
import { LibrarySelector } from '@modules/library/root/components/LibrarySelector';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { libraryMenu, libraryRoutes } from '@routes/library';
import { useLibraryListQuery } from '@modules/library/root/duck/libraryApi';
import { Library, LibraryListResponse } from '@modules/library/root/LibraryTypes';
import { LibrariesSwitcherIcon } from '@components/icons';
import { getMatchedResolver } from '@shared/utils/RoutesUtils';
import RoutesLibrary from '@routes/library/RoutesLibrary';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { useLocation, useNavigate } from 'react-router-dom';

export const LibraryMenu = () => {
  const globalLibrary = useSelector(selectGlobalLibrary);
  const navigate = useNavigate();
  const location = useLocation();
  const { appPermissions } = useAppPermissions();

  // TODO create on backend side api to get all libraries without pagination
  const libraryListQuery = useLibraryListQuery({ page_size: 9999 });
  const libraries = sortBy(libraryListQuery.data?.items, 'name');

  const libraryMenuItems = globalLibrary?.id ? libraryMenu(globalLibrary?.id, appPermissions) : [];

  const onSelectExtraItem = (rawData: Library) => {
    const currentPath = location.pathname;
    const resolver = getMatchedResolver(currentPath, RoutesLibrary);

    if (resolver) {
      navigate(resolver({ libraryId: rawData.id }));
    } else {
      navigate(libraryRoutes.view.resolver({ libraryId: rawData.id }));
    }
  };

  return (
    <MainMenu
      items={libraryMenuItems}
      extraItems={libraries as LibraryListResponse['items']}
      extraItemsIcon={<LibrariesSwitcherIcon />}
      onSelectExtraItem={onSelectExtraItem}
    >
      <LibrarySelector
        libraries={libraries}
        isLoading={libraryListQuery.isLoading}
        onSelectExtraItem={onSelectExtraItem}
      />
    </MainMenu>
  );
};
