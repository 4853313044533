import { SNAPSHOT_TAG_DESCRIPTION } from '@modules/snapshot/duck/snapshotConstants';
import { appApi } from '@config/appApi';
import {
  ISnapshot,
  ISnapshotRetryQuery,
  ISnapshotSaveQuery,
  ISnapshotTableErrorQuery,
  ISnapshotTableErrorResponse,
} from '@modules/snapshot/SnapshotTypes';
import { TableResponse } from '@modules/viewer/duck/viewerApi';
import { TagDescription } from '@reduxjs/toolkit/query/react';

export const SnapshotApiRoutes = {
  list: '/api/snapshot',
  v2List: '/api/v2/snapshot',
  retry: '/api/v2/snapshot/retry',
  sourceTable: '/api/snapshot/source-tables',
  subStudy: 'api/snapshot/sub-study/list',
  snapshotTableErrorDetails: (snapshotId: string, snapshotTableId: string) =>
    `api/v1/snapshot/errors/${snapshotId}/tables/${snapshotTableId}`,
};

export const SnapshotInvalidations: {
  LIST: TagDescription<SNAPSHOT_TAG_DESCRIPTION.LIST>;
  SOURCE_TABLE: TagDescription<SNAPSHOT_TAG_DESCRIPTION.SOURCE_TABLE>;
  ID: (id: number) => TagDescription<SNAPSHOT_TAG_DESCRIPTION.ID>;
  SUB_STUDY: TagDescription<SNAPSHOT_TAG_DESCRIPTION.SUB_STUDY>;
  TABLE_ERROR: (id: string) => TagDescription<SNAPSHOT_TAG_DESCRIPTION.TABLE_ERROR>;
} = {
  LIST: { type: SNAPSHOT_TAG_DESCRIPTION.LIST, id: 'LIST' },
  SOURCE_TABLE: { type: SNAPSHOT_TAG_DESCRIPTION.SOURCE_TABLE, id: 'SOURCE_TABLE' },
  ID: (id: number) => ({ type: SNAPSHOT_TAG_DESCRIPTION.ID, id }),
  SUB_STUDY: { type: SNAPSHOT_TAG_DESCRIPTION.SUB_STUDY, id: 'SUB_STUDY' },
  TABLE_ERROR: (snapshotTableId: string) => ({
    type: SNAPSHOT_TAG_DESCRIPTION.TABLE_ERROR,
    snapshotTableId,
  }),
};

export const SnapshotApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    snapshotList: builder.query<ISnapshot[], void>({
      providesTags: [SnapshotInvalidations.LIST],
      query: () => ({
        url: SnapshotApiRoutes.v2List,
      }),
    }),
    saveSnapshot: builder.mutation<void, ISnapshotSaveQuery>({
      invalidatesTags: [SnapshotInvalidations.LIST],
      query: (data) => ({
        data,
        method: 'POST',
        url: SnapshotApiRoutes.v2List,
        timeout: 0,
      }),
    }),
    retrySnapshot: builder.mutation<void, ISnapshotRetryQuery>({
      invalidatesTags: [SnapshotInvalidations.LIST, SnapshotInvalidations.SOURCE_TABLE],
      query: (data) => ({
        data,
        method: 'POST',
        url: SnapshotApiRoutes.retry,
        timeout: 0,
      }),
    }),
    snapshotSourceTableList: builder.query<TableResponse, void>({
      providesTags: [SnapshotInvalidations.SOURCE_TABLE],
      query: () => ({
        url: SnapshotApiRoutes.sourceTable,
      }),
    }),
    snapshotSubStudy: builder.query<string[], void>({
      providesTags: [SnapshotInvalidations.SUB_STUDY],
      query: () => ({
        url: SnapshotApiRoutes.subStudy,
      }),
    }),
    snapshotTableErrorDetails: builder.query<ISnapshotTableErrorResponse, ISnapshotTableErrorQuery>({
      providesTags: (request, error, data) => [SnapshotInvalidations.TABLE_ERROR(data.snapshotTableId)],
      query: (params) => ({
        url: SnapshotApiRoutes.snapshotTableErrorDetails(params.snapshotId, params.snapshotTableId),
      }),
    }),
  }),
});

export const {
  useSnapshotListQuery,
  useSaveSnapshotMutation,
  useRetrySnapshotMutation,
  useSnapshotSourceTableListQuery,
  useSnapshotSubStudyQuery,
  useLazySnapshotTableErrorDetailsQuery,
  useSnapshotTableErrorDetailsQuery,
} = SnapshotApi;
