import { MenuType } from '@routes/appMenu';
import { StudySwitcherIcon } from '@components/icons';
import { ExtraData } from '@components';
import { TreeDataNode } from 'antd';
import { Key } from 'antd/es/table/interface';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { ReactNode } from 'react';

export interface MenuTreeNode extends TreeDataNode {
  path?: string;
}

export const getGroupKey = (key: string): Key => `${key}-group`;

export const getMenuTreeData = (menu: MenuType): MenuTreeNode[] =>
  menu.map(({ key, title, icon, path, children }) => ({
    path: children ? undefined : path,
    title,
    key: children ? `${key}-group` : key,
    icon: icon ? icon : <></>,
    children: children && getMenuTreeData(children),
    isLeaf: !children,
  }));

export const getParentKeysByChildKey = (menu: MenuType, childKey: Key, parentKeys: Key[] = []): Key[] => {
  for (const node of menu) {
    if (node.children) {
      const childFound = node.children.some((child) => child.key === childKey);
      if (childFound) {
        parentKeys.push(node.key);
        return parentKeys;
      }

      const result = getParentKeysByChildKey(node.children, childKey, [...parentKeys, node.key]);
      if (result.length) {
        return result;
      }
    }
  }

  return [];
};

export const prepareData = (
  menu: MenuType,
  collapsed?: boolean,
  extraItems?: ExtraData[],
  extraItemsIcon?: ReactNode,
): ItemType<MenuItemType>[] => {
  let processedMenu = menu.map(({ key, title, icon, path, children }) => {
    if (collapsed) {
      return {
        path,
        icon,
        key: children ? getGroupKey(key) : key,
        label: title,
        title,
        children: children
          ? [
              {
                key: children ? getGroupKey(key) : key,
                path: undefined,
                label: title,
                title,
                type: 'group',
                children: prepareData(children, false, extraItems, extraItemsIcon),
              },
            ]
          : undefined,
      };
    }
    return {
      key: children ? getGroupKey(key) : key,
      label: title,
      title,
      path: children ? undefined : path,
      icon: icon || <></>,
      children: children ? prepareData(children, false, extraItems, extraItemsIcon) : undefined,
    };
  });

  if (collapsed && extraItems?.length) {
    processedMenu = [
      {
        key: '/extra',
        path: undefined,
        icon: extraItemsIcon || <StudySwitcherIcon />,
        children: extraItems.map(({ rawData }) => ({
          key: `extra-${rawData.id}`,
          label: rawData.name,
          title: rawData.name,
          rawData: rawData,
        })),
        label: 'Root list',
        title: 'Root list',
      },
      ...processedMenu,
    ];
  }

  return processedMenu;
};
