import { capitalizeFirstLetter } from '@shared/utils/Form';
import { IUsersFilterParams } from '@modules/dataset/DatasetTypes';
import { uniqBy } from 'lodash';

export const tableListToOptions = (arr?: Array<{ name: string; id: number | string }>) =>
  uniqBy(
    arr?.map((item) => ({
      text: item.name || '<Empty>',
      value: item.id,
    })) || [],
    'value',
  ).sort((a, b) => a.text.localeCompare(b.text));

export const tableNamesToOptions = (
  arr?: Array<{ [key: string]: any }>,
  field: string = 'name',
  capitalizeLabel: boolean = false,
) =>
  [...new Set((arr || []).map((item) => item[field || '']))]
    .map((value) => ({ text: capitalizeLabel ? capitalizeFirstLetter(value) : value, value }))
    .sort((a, b) => a.text.localeCompare(b.text));

export const getTableListOption = (obj: Record<string, string>, capitalizeLabel: boolean = false) =>
  Object.values(obj)
    .map((value) => ({
      value,
      text: capitalizeLabel ? capitalizeFirstLetter(value) : value,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

export const getListOption = (arr: string[] = []) =>
  [
    ...new Set(
      arr.map((value) => ({
        value,
        text: value || '<Empty>',
      })),
    ),
  ].sort((a, b) => a.text.localeCompare(b.text));

export const filterListToOptions = <K extends keyof IUsersFilterParams>(field: K, arr?: IUsersFilterParams) => {
  const data = arr && (arr[field] as { [key: string]: string });
  if (arr && data) {
    return uniqBy(
      Object.entries(data).map(([k, v]) => ({
        text: v || 'N/A',
        value: v ? k : 'null',
      })) || [],
      'value',
    ).sort((a, b) => a.text.localeCompare(b.text));
  }
  return [];
};
