import { Model, RunModelStatus } from '@modules/model/ModelTypes';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { Button, notification, Space } from '@ui';
import { Pause, Play, SettingIcon } from '@components/icons';
import routes from '@routes';
import { QueryErrorType } from '@shared/utils/Error';
import { useRunModelMutation } from '@modules/model/duck/modelApi';
import { isCrossStudy } from '@shared/utils/common';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';

export const ModelActionButtons = ({
  record,
  status,
  loading,
  isCurrentModelEnv,
  studyId,
  modelEnvLabel,
  t,
}: ModelActionButtonsProps) => {
  const navigate = useNavigate();
  const {
    userPermissions: { canDataModelRun, canDataModelConfigure },
  } = useStudyPermissions();
  const {
    appPermissions: { canCrossDataModelRun, canCrossDataModelConfigure },
  } = useAppPermissions();
  const [runModel, { isLoading: isRunModelLoading }] = useRunModelMutation();
  const [showRunBtn, setShowRunBtn] = useState(true);

  useEffect(() => {
    if (status?.finished_at) {
      setShowRunBtn(true);
    }
  }, [status?.finished_at]);

  const isConfigExist = record.spark_schema?.config && !record.spark_schema?.config?.includes('data_stage.');

  const onRunModelHandler = async (id: number) => {
    try {
      setShowRunBtn(false);
      await runModel(id).unwrap();
      notification.info({ message: t('builder.runNotification') });
    } catch (e) {
      console.error(e);
      setShowRunBtn(true);
      notification.error({
        message: (e as QueryErrorType).data.userMsg,
        description: (e as QueryErrorType).data.devMsg,
        duration: 0,
      });
    }
  };

  const onConfigureModelHandler = (id: number) => {
    const route = isCrossStudy(studyId) ? routes.crossStudy : routes.study;
    navigate(
      route.models.view.resolver({
        modelId: id,
        studyId,
        sourceLabel: modelEnvLabel,
      }),
    );
  };

  const renderRunModelButton = () => {
    if (isRunModelLoading || loading) {
      return <Button loading />;
    }

    if (showRunBtn) {
      return (
        <Button
          title={isConfigExist ? t('rootTable.actionMenu.run') : t('errors.notConfiguredYet')}
          icon={<Play color="lightGrey" />}
          type="text"
          loading={isRunModelLoading}
          disabled={!isConfigExist}
          onClick={() => onRunModelHandler(record.id)}
        />
      );
    }

    return (
      <Button
        title={!status?.started_at ? t('rootTable.status.pending') : t('rootTable.status.started')}
        icon={<Pause color="lightGrey" />}
        type="text"
        onClick={() => setShowRunBtn(true)}
      />
    );
  };

  return (
    <Space>
      {isCurrentModelEnv &&
        (canDataModelRun || (canCrossDataModelRun && isCrossStudy(studyId))) &&
        renderRunModelButton()}
      {(canDataModelConfigure || (canCrossDataModelConfigure && isCrossStudy(studyId))) && (
        <Button
          title={!isCurrentModelEnv ? t('rootTable.actionMenu.viewConfig') : t('rootTable.actionMenu.configure')}
          icon={<SettingIcon color="lightGrey" />}
          type="text"
          loading={false}
          onClick={() => onConfigureModelHandler(record.id)}
        />
      )}
    </Space>
  );
};

interface ModelActionButtonsProps {
  record: Model;
  status?: RunModelStatus;
  t: TFunction;
  loading: boolean;
  isCurrentModelEnv?: boolean;
  studyId: number;
  modelEnvLabel?: string;
}
