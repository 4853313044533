import { customColors, dataStageColors } from '@config/theme/colors';
import { Component, JSXElementConstructor, ReactNode } from 'react';
import { ThemeProvider, Global, css } from '@emotion/react';
import { ConfigProvider, theme } from 'antd';

const { useToken } = theme;

const withAntdConfigProvider = (EmotionComponent: JSXElementConstructor<{ children: ReactNode }>) => {
  return class extends Component<{ children: ReactNode }> {
    render() {
      return (
        <ConfigProvider
          theme={{
            token: {
              paddingXXS: 4,
              paddingXS: 8,
              paddingSM: 12,
              padding: 16,
              paddingMD: 20,
              paddingLG: 24,
              paddingXL: 32,

              marginXXS: 4,
              marginXS: 8,
              marginSM: 12,
              margin: 16,
              marginMD: 20,
              marginLG: 24,
              marginXL: 32,
              marginXXL: 48,

              borderRadiusXS: 2,
              borderRadiusSM: 4,
              borderRadius: 8,
              borderRadiusLG: 8,

              colorBgMask: 'rgba(0, 0, 0, 0.4)',
              colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',

              fontFamily: 'Rubik',

              fontSize: 16, //body-b2
              fontSizeSM: 14, //body-b1
              fontSizeLG: 18, //body-b3
              fontSizeXL: 20,
              fontSizeHeading1: 36,
              fontSizeHeading2: 32,
              fontSizeHeading3: 28,
              fontSizeHeading4: 24,
              fontSizeHeading5: 20,

              lineHeightLG: 1.5,
              lineHeightSM: 1.5,
              lineHeight: 1.5,

              // checkboxes and others will be dark
              colorPrimary: dataStageColors['color-grey-900'],
              colorPrimaryHover: dataStageColors['color-grey-300'],
              colorPrimaryBg: dataStageColors['color-grey-400'],
              colorPrimaryBgHover: dataStageColors['color-grey-300'],
              colorTextDescription: dataStageColors['color-grey-600'],

              colorSplit: dataStageColors['color-grey-300'],
              // colorPrimaryActive: dataStageColors['color-grey-900'],
              colorPrimaryActive: dataStageColors['color-grey-400'],
              // colorPrimaryHover: dataStageColors['color-grey-100'],

              colorBgContainer: dataStageColors['color-white'],
              colorBorder: dataStageColors['color-grey-300'],
              colorLink: dataStageColors['color-brand-blue-500'],
              colorLinkHover: dataStageColors['color-brand-blue-500'],
              colorLinkActive: dataStageColors['color-brand-blue-700'],

              //error
              colorError: dataStageColors['color-error-red-primary'],
              colorErrorText: dataStageColors['color-error-red-primary'],
              colorErrorBg: dataStageColors['color-error-red-light-background'],
              colorErrorBorder: dataStageColors['color-error-red-dark-background'],
              colorErrorHover: dataStageColors['color-error-red-primary-dark'],
              colorErrorActive: dataStageColors['color-error-red-secondary-dark'],

              //info
              colorInfo: dataStageColors['color-brand-blue-700'],
              colorInfoBg: dataStageColors['color-brand-blue-200'],
              colorInfoBorder: dataStageColors['color-brand-blue-400'],

              //warning
              colorWarning: dataStageColors['color-warning-orange-text'],
              colorWarningBg: dataStageColors['color-warning-orange-light-bg'],
              colorWarningBorder: dataStageColors['color-warning-orange-light-border'],

              //success
              colorSuccess: dataStageColors['color-green-icon-light-bg'],
              colorSuccessBg: dataStageColors['color-green-background'],
              colorSuccessBorder: dataStageColors['color-green-icon-dark-bg'],

              colorTextHeading: dataStageColors['color-grey-900'],
              colorTextDisabled: dataStageColors['color-grey-400'],

              // Typography
              colorText: dataStageColors['color-grey-900'],
              colorTextSecondary: dataStageColors['color-grey-600'],
              fontWeightStrong: 400,

              lineHeightHeading1: 1.33,
              lineHeightHeading2: 1.31,
              lineHeightHeading3: 1.29,
              lineHeightHeading4: 1.33,
              lineHeightHeading5: 1.5,
            },
            components: {
              Modal: {
                colorBgMask: 'rgba(0, 0, 0, 0.4)',
                // TODO check in the future, clear  word 'large' in the inputs
                // controlHeight: 40,
              },
              Button: {
                paddingInline: 24,
                paddingBlock: 8,
                paddingInlineLG: 24,
                paddingBlockLG: 8,
                paddingInlineSM: 24,
                paddingBlockSM: 8,

                contentFontSize: 16,
                contentFontSizeLG: 16,
                contentFontSizeSM: 16,

                contentLineHeight: 1.5,
                contentLineHeightLG: 1.5,
                contentLineHeightSM: 1.5,

                borderRadiusLG: 4,
                borderRadiusSM: 4,
                borderRadius: 4,

                onlyIconSize: 24,
                onlyIconSizeLG: 24,
                onlyIconSizeSM: 16,

                fontWeight: 500,
                colorText: dataStageColors['color-grey-900'],
                primaryShadow: 'none',

                primaryColor: dataStageColors['color-white'],
                colorPrimary: dataStageColors['color-brand-blue-500'],
                colorPrimaryHover: dataStageColors['color-brand-blue-600'],
                colorPrimaryActive: dataStageColors['color-brand-blue-700'],

                colorError: dataStageColors['color-error-red-primary'],
                colorErrorBorder: dataStageColors['color-grey-400'],
                colorErrorActive: dataStageColors['color-error-red-primary'],
                colorErrorBgActive: dataStageColors['color-error-red-secondary-dark'],
                colorErrorHover: dataStageColors['color-error-red-primary'],
                colorErrorBorderHover: dataStageColors['color-grey-400'],

                defaultColor: dataStageColors['color-grey-900'],
                defaultBg: dataStageColors['color-white'],
                defaultBorderColor: dataStageColors['color-grey-400'],
                defaultHoverColor: dataStageColors['color-grey-900'],
                defaultHoverBg: dataStageColors['color-grey-100'],
                defaultHoverBorderColor: dataStageColors['color-grey-400'],
                defaultActiveColor: dataStageColors['color-grey-900'],
                defaultActiveBg: dataStageColors['color-grey-300'],
                defaultActiveBorderColor: dataStageColors['color-grey-400'],

                colorBorderSecondary: dataStageColors['color-grey-400'],
                colorBgTextActive: dataStageColors['color-grey-300'],
                textHoverBg: dataStageColors['color-grey-100'],

                defaultGhostColor: dataStageColors['color-grey-900'],
                defaultGhostBorderColor: 'transparent',
              },
              Tabs: {
                itemColor: dataStageColors['color-grey-600'],
                itemHoverColor: dataStageColors['color-grey-600'],
                itemSelectedColor: dataStageColors['color-grey-900'],
                inkBarColor: dataStageColors['color-black'],
                itemActiveColor: dataStageColors['color-black'],

                cardPadding: '8px 16px',
                cardPaddingSM: '8px 16px',
                cardPaddingLG: '8px 16px',
                colorBgContainer: dataStageColors['color-grey-300'],
                colorBorderSecondary: dataStageColors['color-grey-300'],

                horizontalMargin: '0 0 24px 0',
                controlHeight: 40,
                controlHeightLG: 40,
                cardGutter: 0,
                borderRadiusLG: 4,
                borderRadius: 4,

                horizontalItemPadding: '8px 16px',
                horizontalItemPaddingSM: '8px 16px',
                horizontalItemPaddingLG: '8px 16px',
                horizontalItemGutter: 0,
                lineWidth: 2,
              },
              Table: {
                borderColor: dataStageColors['color-grey-300'],
                headerBg: dataStageColors['color-grey-50'],
                headerColor: dataStageColors['color-grey-600'],
                borderRadius: 8,
                linkDecoration: 'underline',
                rowSelectedBg: dataStageColors['color-brand-blue-100'],
                rowSelectedHoverBg: dataStageColors['color-brand-blue-300'],

                cellPaddingInline: 16,
                cellPaddingBlock: 16,

                cellPaddingInlineSM: 16,
                cellPaddingBlockSM: 8,

                fontWeightStrong: 400,
              },
              Pagination: {
                itemSize: 32,
                itemSizeSM: 32,
                controlHeight: 32,
                controlHeightSM: 32,
              },
              Form: {
                labelColor: dataStageColors['color-grey-600'],
                controlHeight: 32,
                controlHeightLG: 40,
                controlHeightSM: 24,
                margin: 16,
                marginLG: 16,
                marginXXS: 4,
                paddingSM: 12,
              },
              Tree: {
                colorText: dataStageColors['color-grey-600'],
                nodeSelectedBg: dataStageColors['color-brand-blue-200'],
                directoryNodeSelectedColor: dataStageColors['color-brand-blue-500'],
                directoryNodeSelectedBg: dataStageColors['color-brand-blue-200'],
                titleHeight: 40,
                controlInteractiveSize: 24,
                paddingXS: 12,
                marginXXS: 4,
                marginXS: 4,
                fontSize: 14,
                lineHeight: 1.5,
              },
              Badge: {
                colorError: dataStageColors['color-error-red-primary-dark'],
              },
              Input: {
                borderRadius: 4,
                borderRadiusLG: 4,
                borderRadiusSM: 4,
                colorBorder: dataStageColors['color-grey-400'],

                colorTextPlaceholder: dataStageColors['color-grey-600'],
                hoverBg: dataStageColors['color-grey-100'],
                hoverBorderColor: dataStageColors['color-grey-400'],

                activeBorderColor: dataStageColors['color-grey-600'],
                colorTextDisabled: dataStageColors['color-grey-400'],
                colorBgContainerDisabled: dataStageColors['color-grey-200'],
                colorTextDescription: dataStageColors['color-grey-600'],

                activeShadow: 'none',
                errorActiveShadow: 'none',
              },
              InputNumber: {
                borderRadius: 4,
                borderRadiusLG: 4,
                borderRadiusSM: 4,
                colorBorder: dataStageColors['color-grey-400'],

                colorTextPlaceholder: dataStageColors['color-grey-600'],
                hoverBg: dataStageColors['color-grey-100'],
                hoverBorderColor: dataStageColors['color-grey-400'],

                activeBorderColor: dataStageColors['color-grey-600'],
                colorTextDisabled: dataStageColors['color-grey-400'],
                colorBgContainerDisabled: dataStageColors['color-grey-200'],
                colorTextDescription: dataStageColors['color-grey-600'],

                activeShadow: 'none',
                errorActiveShadow: 'none',
              },
              Dropdown: {
                // controlItemBgHover: dataStageColors['color-grey-100'],
                controlItemBgActive: dataStageColors['color-grey-300'],
                controlItemBgActiveHover: dataStageColors['color-grey-100'],
              },
              Select: {
                borderRadius: 4,
                borderRadiusLG: 4,
                borderRadiusSM: 4,
                colorBorder: dataStageColors['color-grey-400'],

                colorTextPlaceholder: dataStageColors['color-grey-600'],
                hoverBorderColor: dataStageColors['color-grey-500'],
                activeBorderColor: dataStageColors['color-grey-500'],
                activeOutlineColor: 'none',

                colorTextDisabled: dataStageColors['color-grey-400'],
                colorBgContainerDisabled: dataStageColors['color-grey-200'],
                colorTextDescription: dataStageColors['color-grey-600'],

                optionActiveBg: dataStageColors['color-grey-100'],
                optionSelectedBg: dataStageColors['color-grey-300'],
              },
              Radio: {
                dotSize: 14,
                radioSize: 20,
                colorPrimary: dataStageColors['color-grey-900'],
                colorPrimaryActive: dataStageColors['color-grey-500'],
                dotColorDisabled: dataStageColors['color-grey-500'],
                borderRadiusLG: 4,
                buttonPaddingInline: 8,
                // colorBorder: dataStageColors['color-grey-300'],
                // colorPrimary: dataStageColors['color-brand-blue-500'],
              },
              Switch: {
                colorPrimary: dataStageColors['color-grey-900'],
                colorTextQuaternary: dataStageColors['color-grey-400'],
              },
              DatePicker: {
                borderRadius: 4,
                borderRadiusLG: 4,
                borderRadiusSM: 4,
                colorBorder: dataStageColors['color-grey-400'],
                colorTextPlaceholder: dataStageColors['color-grey-600'],
                hoverBg: dataStageColors['color-grey-100'],
                hoverBorderColor: dataStageColors['color-grey-400'],
                activeBorderColor: dataStageColors['color-grey-600'],
                colorBgContainerDisabled: dataStageColors['color-grey-200'],

                colorPrimary: dataStageColors['color-grey-400'],
                // cellHoverBg: dataStageColors['color-grey-100'],
                colorTextLightSolid: dataStageColors['color-grey-900'],
                activeShadow: 'none',
              },
              Divider: {
                colorSplit: dataStageColors['color-grey-400'],
              },
              Tag: {
                colorErrorBg: dataStageColors['color-error-red-dark-background'],
              },
              Checkbox: {
                colorTextDisabled: 'rgba(132,145,154,0.4)',
                colorBgContainerDisabled: dataStageColors['color-grey-200'],
              },
              Alert: {
                borderRadiusLG: 4,
                colorInfoBg: dataStageColors['color-brand-blue-100'],
                colorErrorBg: dataStageColors['color-error-red-light-background'],
                colorSuccessBg: dataStageColors['color-green-icon-light-bg'],
                colorWarningBg: dataStageColors['color-alert-yellow-background'],
                colorWarningBorder: dataStageColors['color-alert-yellow-icon-dark-bg'],
              },
              Steps: {
                colorPrimary: dataStageColors['color-grey-900'],
                colorTextDescription: dataStageColors['color-grey-900'],
                colorSplit: dataStageColors['color-grey-400'],
                colorTextLightSolid: dataStageColors['color-white'],
                iconFontSize: 20,
                fontSize: 18,
                iconSize: 40,
                titleLineHeight: 40,
              },
              Upload: {
                colorFillAlter: dataStageColors['color-white'],
              },
              Notification: {
                colorInfo: dataStageColors['color-green-icon-light-bg'],
                colorError: dataStageColors['color-error-red-primary'],
                colorSuccess: dataStageColors['color-brand-blue-500'],
                colorWarning: dataStageColors['color-alert-yellow-icon-dark-bg'],
                fontSizeLG: 18,
                fontSize: 16,
                borderRadiusSM: 8,
                borderRadiusLG: 8,
              },
            },
          }}
        >
          <EmotionComponent {...this.props} />
          <Global
            styles={css`
              .root {
                overflow: hidden;
                font-variant: lining-nums;
              }
              .ant-input-disabled,
              .ant-picker-disabled,
              .ant-select.ant-select-disabled > .ant-select-selector {
                border: none;
              }
              .ant-select:hover .ant-select-selector {
                background: ${dataStageColors['color-grey-100']};
              }
            `}
          />
        </ConfigProvider>
      );
    }
  };
};

export const EmotionAntdThemeProvider = withAntdConfigProvider(({ children }: { children: ReactNode }) => {
  const { token } = useToken();

  return <ThemeProvider theme={{ ...token, ...customColors, ...dataStageColors }}>{children}</ThemeProvider>;
});
