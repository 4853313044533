import { Menu, Skeleton } from '@components/ui';
import { selectMainMenuState } from '@app/duck/appSelectors';
import { MenuType } from '@routes/appMenu';
import { appSlice } from '@app/duck/appSlice';
import { CustomRouteMatch } from '@routes/RoutesTypes';
import { StudyResponse } from '@modules/study/StudyTypes';
import { Library } from '@modules/library/root/LibraryTypes';
import { ChevronDownIcon } from '@components/icons';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatches, useNavigate } from 'react-router-dom';
import { MenuProps } from 'antd/es/menu';
import { CSSObject, Theme } from '@emotion/react';
import { COLLAPSED_WIDTH, ResizablePanel } from './components/ResizablePanel';
import { getParentKeysByChildKey, prepareData } from './duck/utils';

export const MainMenu = ({
  children,
  items,
  extraItems,
  extraItemsIcon,
  onSelectExtraItem,
  isLoading = false,
}: MainMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMatches();
  const match = matches.at(-1) as CustomRouteMatch;
  const menuState = useSelector(selectMainMenuState);

  const [collapsed, setCollapsed] = useState(menuState.width === COLLAPSED_WIDTH);

  const extraListItems = useMemo(
    () => extraItems?.map((el) => ({ key: `extra-${el.id}`, label: el.name, rawData: el })),
    [extraItems],
  );

  const menuData = useMemo(
    () => prepareData(items, collapsed, extraListItems, extraItemsIcon) ?? [],
    [items, collapsed, extraItemsIcon, extraListItems],
  );

  useEffect(() => {
    if (!isLoading) {
      const parentKeysOfSelectedNode = getParentKeysByChildKey(menuData as MenuType, match.id);
      const { expandedKeys = [] } = menuState;
      const newExpandedKeys = parentKeysOfSelectedNode.filter((key) => !expandedKeys.includes(key));
      if (newExpandedKeys.length) {
        dispatch(appSlice.actions.setMainMenuState({ expandedKeys: expandedKeys.concat(newExpandedKeys) }));
      }
    }
  }, [isLoading, menuData]);

  const handleSelectItem: MenuProps['onSelect'] = (data) => {
    if (data.key.startsWith('extra')) {
      const rawData = (data as any).item.props.rawData as TRawData;
      onSelectExtraItem?.(rawData);
    } else {
      navigate((data as any).item.props.path);
    }
  };

  const handleExpandGroupItem: MenuProps['onOpenChange'] = (expandedKeys) => {
    if (!collapsed) {
      dispatch(appSlice.actions.setMainMenuState({ expandedKeys }));
    }
  };

  const handlePanelWidthChange = (width: number) => {
    dispatch(appSlice.actions.setMainMenuState({ width }));
  };

  return (
    <ResizablePanel onWidthChange={handlePanelWidthChange} width={menuState.width} setCollapsed={setCollapsed}>
      {!collapsed && children}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div css={cssContainer(collapsed)}>
          <Menu
            key={collapsed ? 'collapsed' : 'expanded'}
            css={cssMenu(collapsed)}
            selectedKeys={[match.id]}
            openKeys={collapsed ? undefined : (menuState.expandedKeys as string[])}
            mode="inline"
            inlineCollapsed={collapsed}
            items={menuData}
            onSelect={handleSelectItem}
            onOpenChange={handleExpandGroupItem}
            inlineIndent={8}
            expandIcon={({ isOpen = false }) => <ChevronDownIcon css={cssSwitcherIcon(isOpen)} />}
            getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
          />
        </div>
      )}
    </ResizablePanel>
  );
};

interface MainMenuProps {
  children?: ReactNode;
  items: MenuType;
  isLoading?: boolean;
  extraItems?: StudyResponse[] | Library[] | null;
  onSelectExtraItem?: (props: any) => void;
  extraItemsIcon?: ReactNode;
}

type TRawData = StudyResponse | Library;

export interface ExtraData {
  key: string;
  label: string;
  rawData: TRawData;
}

const cssMenu =
  (isCollapsed: boolean) =>
  (theme: Theme): CSSObject => ({
    fontSize: 14,
    userSelect: 'none',

    '.ant-menu-item-group-title': {
      borderBottom: `solid 1px ${theme['color-grey-300']}`,
    },

    '&&.ant-menu-inline-collapsed': {
      width: 56,

      '.ant-menu-item, .ant-menu-submenu-title': {
        marginBlock: 8,
        marginInline: 8,
        width: 'auto',
        paddingInlineStart: 8,
        paddingInlineEnd: 8,
        borderRadius: 4,
        fontSize: 14,
      },
      '.ant-menu-item-icon': {
        verticalAlign: 'middle',
      },
      '.ant-menu-item-group-title': {
        color: theme['color-grey-900'],
        paddingInlineStart: 16,
      },
    },

    '&&': {
      '&& .ant-menu-item-icon': {
        fontSize: 24,
      },

      '&&.ant-menu-root': {
        borderInlineEnd: 'none',
      },

      '.ant-menu-item-selected': {
        backgroundColor: theme['color-brand-blue-200'],
        color: theme['color-brand-blue-500'],
        borderRadius: 4,
      },

      '&& .ant-menu-title-content': {
        marginInlineStart: 12,
        display: 'inline',
      },

      '&& .ant-menu-item': {
        color: theme['color-grey-600'],

        ...(!isCollapsed && {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }),

        '&:hover, &:active': {
          background: theme['color-grey-200'],
          borderRadius: 4,
        },
      },

      '.ant-menu-submenu-title': {
        color: theme['color-grey-600'],
        paddingRight: 4,
        marginInline: 8,

        '&:active': {
          background: theme['color-grey-200'],
          borderRadius: 4,
        },
      },
    },

    '.ant-menu-sub': {
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme['color-grey-300']} transparent`,

      '.ant-menu-item': {
        paddingLeft: '0 !important',
        height: 37,
      },

      '&.ant-menu-inline': {
        position: 'relative',
        paddingLeft: 48,
        overflow: 'hidden',

        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 28,
          width: 3,
          height: '100%',
          backgroundColor: theme['color-grey-200'],
          borderRadius: 4,
          transition: 'height 0.3s ease',
          pointerEvents: 'none',
        },
      },
    },

    '&& .ant-menu-sub.ant-menu-inline': {
      background: 'transparent',
    },
  });

const cssSwitcherIcon = (expanded: boolean) => (): CSSObject => ({
  height: '24px',
  width: '24px',
  color: 'inherit',
  transition: '0.3s all',
  transform: expanded ? 'rotate(180deg)' : undefined,
});

const cssContainer =
  (collapsed: boolean) =>
  (theme: Theme): CSSObject => ({
    ...(!collapsed && {
      height: '100%',
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme['color-grey-300']} transparent`,
    }),
  });
