import { IDataset } from '@modules/dataset/DatasetTypes';
import { ELibrarySourceType, IBaseLibraryEntitySystemInfo } from '@modules/library/root/LibraryTypes';
import { prepareLibraryObject } from '@modules/library/root/duck/libraryUtils';
import {
  IPrepareStackDatasetTableData,
  IStackDatasetObjectConfiguration,
  IStackDatasetObject,
} from '@modules/dnaObject/StackDataset/AnalysisObjectStackDatasetTypes';

export function prepareStackDatasetTableData(
  props: IPrepareStackDatasetTableDataFromStudy,
): IPrepareStackDatasetTableData<IDataset>;
export function prepareStackDatasetTableData(
  props: IPrepareStackDatasetTableDataFromDnAObject,
): IPrepareStackDatasetTableData<IStackDatasetObject>;
export function prepareStackDatasetTableData(
  props: IPrepareStackDatasetTableDataFromStudy | IPrepareStackDatasetTableDataFromDnAObject,
) {
  if (!Array.isArray(props.data)) return [];

  if (props.sourceType === ELibrarySourceType.Study) {
    return props.data.map((item) => ({
      id: item.id,
      name: item.name,
      scope: item.scope,
      rawData: item,
    }));
  }

  return [] as IPrepareStackDatasetTableData<IStackDatasetObject>;
}

export const prepareStackDatasetObjectForImport = (
  values: IDataset[] | IStackDatasetObject[],
  systemInfo?: IBaseLibraryEntitySystemInfo,
) => {
  return prepareLibraryObject<IStackDatasetObjectConfiguration>(
    values.map((item) => {
      if ('object_scope' in item) {
        return { ...item, configuration: item.configuration! };
      }
      return {
        name: item.name,
        configuration: { name: item.name, scope: item.scope, query: item.query },
      };
    }),
    systemInfo,
  ).map((item) => ({ ...item, source_id: null, description: '' }));
};

interface IPrepareStackDatasetTableDataFromStudy {
  data: IDataset[];
  sourceType: ELibrarySourceType.Study;
  studyId: number;
  protocolId: string;
}

interface IPrepareStackDatasetTableDataFromDnAObject {
  data: IStackDatasetObject[];
  sourceType: ELibrarySourceType.Library;
  studyId: number;
}
