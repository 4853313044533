export enum ANALYSIS_OBJECT_TAG_DESCRIPTION {
  'SQL_LAB_LIST' = 'ANALYSIS_OBJECT_SQL_LAB_LIST',
  'SQL_LAB_ITEM' = 'ANALYSIS_OBJECT_SQL_LAB_ITEM',
  'SQL_LAB_FILTERS' = 'ANALYSIS_OBJECT_SQL_LAB_FILTERS',
  'CODE_LAB_LIST' = 'ANALYSIS_OBJECT_CODE_LAB_LIST',
  'CODE_LAB_ITEM' = 'ANALYSIS_OBJECT_CODE_LAB_ITEM',
  'STACK_DATASET_LIST' = 'ANALYSIS_OBJECT_STACK_DATASET_LIST',
  'STACK_DATASET_ITEM' = 'ANALYSIS_OBJECT_STACK_DATASET_ITEM',
  'STACK_DATASET_FILTERS' = 'ANALYSIS_OBJECT_STACK_DATASET_FILTERS',
}
