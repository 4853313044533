import { Typography } from '@ui';
import VirtualList from 'rc-virtual-list';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/react';
import { NotificationItem } from './NotificationItem';
import { TNotification } from '../duck/notificationsApi';

export const NotificationsArchivedPane = ({ archived }: NotificationsArchivedPaneProps) => {
  const { t } = useTranslation(['shared']);

  return (
    <>
      {archived.length === 0 && (
        <div css={cssText}>
          <Typography.Text type="secondary">{t('notification.archivedNotFound')}</Typography.Text>
        </div>
      )}
      <VirtualList data={archived} height={490} itemHeight={100} itemKey="notificationId">
        {(notification) => (
          // div is really needed to avoid a blank extra spaces in the list during scrolling
          <div>
            <NotificationItem key={notification.notificationId} notification={notification} selectable={false} />
          </div>
        )}
      </VirtualList>
    </>
  );
};

const cssText = (): CSSObject => ({
  width: '100%',
  textAlign: 'center',
});

interface NotificationsArchivedPaneProps {
  archived: TNotification[];
}
