import { StoreListResponse } from '@modules/stores/duck/storeApi';
import { isDefaultStore } from '@modules/stores/utils';
import { DEFAULT_STORES_CONNECTION } from '@modules/stores/duck/storeConstants';
import { Table, Tag, Typography } from '@ui';
import { getCreatedByInfo } from '@shared/utils/common';
import { SourceListResponse } from '@modules/source/duck/sourceApi';
import { tableListToOptions } from '@shared/utils/Table';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { dateToString } from '@shared/utils/Date';
import { storeActions } from '@modules/stores/duck/storeSlice';
import { StoreModalsType } from '@modules/stores/modals';
import { ActorAndInfo } from '@components';
import { TableColumnsType, TableProps } from 'antd';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TFunction } from 'i18next';

export const StoreList = ({ data, sourceList, loading, pagination, onChange, t }: StoreListProps) => {
  const dispatch = useDispatch();

  const { getColumnSearchProps, locale } = useColumnSearch<StoreListResponse['items'][0]>();
  const sourceListTransformed = tableListToOptions(sourceList) || [];
  const sourceListOption = [...sourceListTransformed, DEFAULT_STORES_CONNECTION].sort((a, b) =>
    a.text.localeCompare(b.text),
  );

  const openStoreModal = (data: StoreListResponse['items'][0]) => {
    dispatch(storeActions.pushModal({ type: StoreModalsType.saveStore, data }));
  };

  const columns: TableColumnsType<StoreListResponse['items'][0]> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        render: (name, record) =>
          isDefaultStore(record.id) || record.system ? (
            record.name
          ) : (
            <Typography.Link children={name} onClick={() => openStoreModal(record)} />
          ),
      },
      {
        title: t('rootTable.description'),
        dataIndex: 'description',
      },
      {
        title: t('rootTable.dataSource'),
        dataIndex: 'data_source_id',
        filters: sourceListOption,
        onFilter: (value, record) => record.data_source_id === value,
        render: (_, record) => record.data_source_name,
      },
      {
        title: t('rootTable.created'),
        customKey: 'narrow',
        dataIndex: 'created_at',
        sorter: (a, b) => a.created_at - b.created_at,
        sortDirections: ['ascend'],
        render: (createdAt: number, record) => (
          <ActorAndInfo info={dateToString(createdAt)} actor={getCreatedByInfo(record)} />
        ),
      },
      {
        width: 150,
        title: t('rootTable.active'),
        dataIndex: 'active',
        render: (active: boolean) => (
          <Tag color={active ? 'success' : 'error'}>{active ? t('activeStatus') : t('nonActiveStatus')}</Tag>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceListOption, sourceList],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data}
      loading={loading}
      rowKey={(item) => item.id}
      onChange={onChange}
      tableLayout="fixed"
      scroll={{ x: 900 }}
      pagination={pagination}
    />
  );
};

interface StoreListProps {
  studyId: number;
  data?: StoreListResponse['items'];
  sourceList?: SourceListResponse['items'];
  pagination?: TableProps<StoreListResponse['items'][0]>['pagination'];
  onChange?: TableProps<StoreListResponse['items'][0]>['onChange'];
  loading?: boolean;
  pageSize?: number;
  disableKindSort?: boolean;
  t: TFunction;
}
