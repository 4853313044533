import { useDispatch, useSelector } from 'react-redux';
import {
  AnalysisObjectSQLLabModalsImport,
  IAnalysisObjectSQLLabModalsImportProps,
} from './AnalysisObjectSQLLabModalsImport';
import {
  AnalysisObjectCodeLabModalsImport,
  IAnalysisObjectCodeLabModalsImportProps,
} from './AnalysisObjectCodeLabModalsImport';
import {
  AnalysisObjectStackDatasetModalsImport,
  IAnalysisObjectStackDatasetModalsImportProps,
} from './AnalysisObjectStackDatasetModalsImport';
import { selectAnalysisObjectModals } from '../duck/analysisObjectSelectors';
import { analysisObjectActions } from '../duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '../AnalysisObjectTypes';

export const AnalysisObjectModalsController = () => {
  const dispatch = useDispatch();
  const { data, selectedModal } = useSelector(selectAnalysisObjectModals);

  const closeModal = () => {
    dispatch(analysisObjectActions.dropModal());
  };

  return (
    <>
      <AnalysisObjectSQLLabModalsImport
        open={selectedModal === EAnalysisObjectModalsType.importSQLDataset}
        data={data as IAnalysisObjectSQLLabModalsImportProps['data']}
        onClose={closeModal}
      />
      <AnalysisObjectCodeLabModalsImport
        open={selectedModal === EAnalysisObjectModalsType.importCodeLab}
        data={data as IAnalysisObjectCodeLabModalsImportProps['data']}
        onClose={closeModal}
      />
      <AnalysisObjectStackDatasetModalsImport
        open={selectedModal === EAnalysisObjectModalsType.importStackDataset}
        data={data as IAnalysisObjectStackDatasetModalsImportProps['data']}
        onClose={closeModal}
      />
    </>
  );
};
