import { PageTemplateSimple } from '@components';
import { Typography } from '@ui';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { isCrossStudy } from '@shared/utils/common';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useTranslation } from 'react-i18next';
import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { useCodeLabJobListQuery, useCodeLabSourceListQuery } from '../duck/codeLabApi';
import { CodeLabJobList } from '../components/CodeLabJobList';
import { CodeLabModalsController } from '../modals';

const wrapMixPermissions = (Component: ComponentType<ICodeLabJobsRootPageProps>) => {
  const WrapperComponent = () => {
    const globalStudy = useSelector(selectGlobalStudy);
    const {
      appPermissions: { canCrossClSchedulerInsert, canCrossClSchedulerDelete },
    } = useAppPermissions();
    const {
      userPermissions: { canClSchedulerInsert, canClSchedulerDelete },
    } = useStudyPermissions();

    let props = { mixClSchedulerInsert: canClSchedulerInsert, mixClSchedulerDelete: canClSchedulerDelete };
    if (isCrossStudy(globalStudy?.id)) {
      props.mixClSchedulerInsert = canCrossClSchedulerInsert;
      props.mixClSchedulerDelete = canCrossClSchedulerDelete;
    }

    return <Component {...props} />;
  };

  return WrapperComponent;
};

export const CodeLabJobsRootPage = wrapMixPermissions((rootPageProps) => {
  const { t } = useTranslation('codeLab');

  const codeLabJobList = useCodeLabJobListQuery();
  const codeLabSourceListQuery = useCodeLabSourceListQuery();

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        refetch: () => {
          codeLabJobList.refetch();
          codeLabSourceListQuery.refetch();
        },
      }}
      content={{
        errorText: t('loadingError'),
        error: codeLabJobList.error || codeLabSourceListQuery.error,
      }}
    >
      <Typography.Paragraph>{t('pageRootDescription')}</Typography.Paragraph>
      <CodeLabJobList
        jobList={codeLabJobList.data?.items}
        sourceList={codeLabSourceListQuery.data}
        loading={codeLabJobList.isFetching || codeLabSourceListQuery.isFetching}
        {...rootPageProps}
      />
      <CodeLabModalsController />
    </PageTemplateSimple>
  );
});

interface ICodeLabJobsRootPageProps {
  mixClSchedulerInsert?: boolean;
  mixClSchedulerDelete?: boolean;
}
