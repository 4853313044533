import { ActorAndInfo } from '@components';
import { dateToTimezoneString, timestampToUNIX } from '@shared/utils/Date';
import { Table, Tag, Space } from '@ui';
import { AntdIconBox } from '@components/icons';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ISnapshot, SNAPSHOT_STATUS } from '@modules/snapshot/SnapshotTypes';
import routes from '@routes';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { beautifyUsername } from '@shared/utils/common';
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SNAPSHOT_STATUS_COLOR_MAPPING, SNAPSHOT_STATUS_MAPPING } from '../duck/snapshotConstants';

const SnapshotStatusOverview = ({ tables, t, withIcon }: SnapshotStatusOverviewProps) => {
  if (
    tables?.some((table) =>
      [SNAPSHOT_STATUS.IN_PROGRESS, SNAPSHOT_STATUS.NEW, SNAPSHOT_STATUS.COMPLETED_CH].includes(table.status),
    )
  ) {
    return (
      <Tag
        color={SNAPSHOT_STATUS_COLOR_MAPPING[SNAPSHOT_STATUS.IN_PROGRESS]}
        children={SNAPSHOT_STATUS_MAPPING[SNAPSHOT_STATUS.IN_PROGRESS]}
      />
    );
  }

  const errorTables = tables?.filter((table) => table.status === SNAPSHOT_STATUS.FAILED) || [];

  if (errorTables.length !== 0) {
    if (tables.length === errorTables.length) {
      return (
        <Tag
          color={SNAPSHOT_STATUS_COLOR_MAPPING[SNAPSHOT_STATUS.FAILED]}
          children={SNAPSHOT_STATUS_MAPPING[SNAPSHOT_STATUS.FAILED]}
        />
      );
    }
    return (
      <Tag
        color={SNAPSHOT_STATUS_COLOR_MAPPING[SNAPSHOT_STATUS.PARTIAL]}
        children={SNAPSHOT_STATUS_MAPPING[SNAPSHOT_STATUS.PARTIAL]}
      />
    );
  }

  return (
    <Space>
      <Tag
        color={SNAPSHOT_STATUS_COLOR_MAPPING[SNAPSHOT_STATUS.COMPLETED]}
        children={SNAPSHOT_STATUS_MAPPING[SNAPSHOT_STATUS.COMPLETED]}
      />
      {withIcon && errorTables.length > 0 && (
        <AntdIconBox
          icon={QuestionCircleOutlined}
          tip={t('rootTable.tableFailed', { count: errorTables.length })}
          color="danger"
        />
      )}
    </Space>
  );
};

export const SnapshotList = ({ data, loading, t }: SnapshotListProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { getColumnSearchProps, locale } = useColumnSearch<ISnapshot>();

  const columns: TableColumnsType<ISnapshot> = useMemo(
    () => [
      {
        title: t('rootTable.name'),
        dataIndex: 'description',
        key: 'name',
        ...getColumnSearchProps('description'),
        render: (name, record) => (
          <Link
            children={name}
            to={routes.study.snapshots.view.resolver({ studyId: globalStudy?.id!, snapshotId: record.id })}
            state={record}
          />
        ),
      },
      {
        width: 250,
        title: t('rootTable.created'),
        customKey: 'narrow',
        dataIndex: 'createdAt',
        sorter: (a, b) => timestampToUNIX(a.createdAt) - timestampToUNIX(b.createdAt),
        sortDirections: ['descend'],
        render: (_, record) => (
          <ActorAndInfo info={dateToTimezoneString(record.createdAt)} actor={beautifyUsername(record.created_by)} />
        ),
      },
      {
        width: 250,
        title: t('rootTable.cutoffDate'),
        dataIndex: 'cutoffDate',
        render: (_, record) => dateToTimezoneString(record?.cutoffDate),
      },
      {
        width: 80,
        title: t('rootTable.tables'),
        dataIndex: 'tables',
        render: (_, record) => Object.values(record.tablesDetails).length,
      },
      {
        width: 120,
        title: t('rootTable.completed'),
        dataIndex: 'completedTables',
        render: (_, record) =>
          (record.tablesDetails?.filter((table) => table.status === SNAPSHOT_STATUS.COMPLETED) || []).length,
      },
      {
        width: 80,
        title: t('rootTable.failed'),
        dataIndex: 'errorTables',
        render: (_, record) =>
          (record.tablesDetails?.filter((table) => table.status === SNAPSHOT_STATUS.FAILED) || []).length,
      },
      {
        width: 150,
        title: t('rootTable.status'),
        dataIndex: 'status',
        render: (_, record) => <SnapshotStatusOverview t={t} tables={record.tablesDetails} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Table
      locale={locale}
      columns={columns}
      dataSource={data}
      loading={loading}
      tableLayout="fixed"
      scroll={{ x: 1100 }}
      pagination={false}
      rowKey={(item) => item.id}
    />
  );
};

interface SnapshotListProps {
  data?: ISnapshot[];
  loading: boolean;
  t: TFunction;
}

interface SnapshotStatusOverviewProps {
  tables: ISnapshot['tablesDetails'];
  t: TFunction;
  withIcon?: boolean;
}
