import { DraggableModal } from '@ui';
import { useImportAnalysisObjectStackDatasetMutation } from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { IDataset } from '@modules/dataset/DatasetTypes';
import {
  IStackDatasetObjectSelectFormProps,
  StackDatasetObjectSelectForm,
} from '@modules/dnaObject/StackDataset/components/StackDatasetObjectSelectForm';
import { prepareStackDatasetObjectForImport } from '@modules/dnaObject/StackDataset/duck/stackDatasetObjectUtils';
import { IStackDatasetObject } from '@modules/dnaObject/StackDataset/AnalysisObjectStackDatasetTypes';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

const AnalysisObjectStackDatasetModalsImportContent = ({
  onClose,
  t,
}: IAnalysisObjectStackDatasetModalsImportContentProps) => {
  const [importAOStackDatasetDataset] = useImportAnalysisObjectStackDatasetMutation();

  const onImport: IStackDatasetObjectSelectFormProps['onSubmit'] = async (
    values: IDataset[] | IStackDatasetObject[],
    { systemInfo, kind },
  ) => {
    return await importAOStackDatasetDataset({
      data: prepareStackDatasetObjectForImport(values, systemInfo),
      source: kind,
    }).unwrap();
  };

  return <StackDatasetObjectSelectForm onClose={onClose} onSubmit={onImport} hideOverwriteHandles onlyCurrentEnv />;
};

export const AnalysisObjectStackDatasetModalsImport = ({
  open,
  data,
  onClose,
}: IAnalysisObjectStackDatasetModalsImportProps) => {
  const { t } = useTranslation(['analysisObjects']);

  return (
    <DraggableModal
      css={cssModal}
      width={800}
      open={open}
      onCancel={onClose}
      title={t('stackDataset.importForm.title')}
      footer={null}
      destroyOnClose
    >
      {open && <AnalysisObjectStackDatasetModalsImportContent data={data} onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssModal = (): CSSObject => ({
  '& .ant-modal-content': {
    minWidth: 800,
  },
});

export interface IAnalysisObjectStackDatasetModalsImportProps {
  open: boolean;
  data: any;
  onClose: () => void;
}

interface IAnalysisObjectStackDatasetModalsImportContentProps
  extends Pick<IAnalysisObjectStackDatasetModalsImportProps, 'data' | 'onClose'> {
  t: TFunction;
}
