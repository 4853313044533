export const dataStageColors = {
  // Category "Brand blue"
  'color-brand-blue-700': '#00557f',
  'color-brand-blue-600': '#006191',
  'color-brand-blue-500': '#0079b5',
  'color-brand-blue-400': '#4da1cb',
  'color-brand-blue-300': '#b3d7e9',
  'color-brand-blue-200': '#e6f2f8',
  'color-brand-blue-100': '#f5f9ff',

  // Category "Brand grey"
  'color-brand-grey': '#425563',

  // Category "Greys"
  'color-black': '#0a0e10',

  'color-grey-900': '#12161a',
  'color-grey-800': '#151f24',
  'color-grey-700': '#48535b',
  'color-grey-600': '#64727d',
  'color-grey-500': '#84919a',
  'color-grey-400': '#babdcc',
  'color-grey-300': '#dde2e4',
  'color-grey-200': '#eef0f2',
  'color-grey-100': '#f3f4f6',
  'color-grey-50': '#f9f9f9',
  'color-off-white': '#fbfbfb',
  'color-white': '#ffffff',

  // Category "Error red"
  'color-error-red-secondary-dark': '#a11212',
  'color-error-red-primary-dark': '#c21919',
  'color-error-red-primary': '#d21c1c',
  'color-error-red-dark-background': '#ffe5e5',
  'color-error-red-light-background': '#fff5f5',

  // Category "Green"
  'color-green-text': '#00783e',
  'color-green-icon-light-bg': '#16a864',
  'color-green-icon-dark-bg': '#47b881',
  'color-green-background': '#e8f5d7',

  // Category "Alert yellow"
  'color-alert-yellow-text': '#996a13',
  'color-alert-yellow-icon-light-bg': '#c7821c',
  'color-alert-yellow-icon-dark-bg': '#ffc043',
  'color-alert-yellow-background': '#fffae0',

  // Category "Warning"
  'color-warning-orange-text': '#996a13',
  'color-warning-orange-light-bg': '#fcf5e8',
  'color-warning-orange-light-border': '#c7821c',

  // Category "Chart & infographic colors"
  'color-chart-blue-01': '#a5b3dd',

  'color-chart-cyan-01': '#c0d6df',
  'color-chart-cyan-02': '#8ecccc',

  'color-chart-green-01': '#d1da82',
  'color-chart-green-02': '#acc18a',
  'color-chart-green-03': '#5f6f57',
  'color-chart-green-04': '#8dab7f',

  'color-chart-orange-01': '#e88d69',
  'color-chart-orange-02': '#e8dab2',
  'color-chart-orange-03': '#eeb583',

  'color-chart-grey-01': '#5b6c73',
  'color-chart-grey-02': '#85979f',
  'color-chart-grey-03': '#98a7ae',

  'color-chart-yellow-01': '#eae9d9',
  'color-chart-yellow-02': '#cbc89f',
};

export const customColors = {
  colorBgLayoutSecondary: dataStageColors['color-grey-800'],
  colorTextInverse: 'rgba(255, 255, 255, 0.88)',
  'primary-1': '#f4f6f7',
  'primary-2': '#ced8db',
  'primary-3': '#c0cacf',
  'primary-4': '#95b4c2',
  'primary-5': '#6e9cb5',
  'primary-6': '#4b85a8',
  'primary-7': '#346282',
  'primary-8': '#20415c',
  'primary-9': '#102336',
  'primary-10': '#040a0f',
  'natural-1': '#ccd1d6',
  'natural-2': '#BABDCC',
  'natural-3': '#9fa9b2',
  'natural-4': '#8895a0',
  'natural-5': '#71818e',
  'natural-6': '#64727D',
  'natural-7': '#56626c',
  'natural-8': '#444d55',
  'natural-9': '#32393e',
  'natural-10': '#242a2d',
};
