import { selectGlobalStudy } from '@app/duck/appSelectors';
import { Beaker, StudySwitcherIcon } from '@components/icons';
import { MenuSelector } from '@components/MainMenu/components/MenuSelector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TreeDataNode, TreeProps } from 'antd';
import { StudyListResponse, StudyResponse } from '../StudyTypes';

export const StudySelector = ({ studies, isLoading, onSelectExtraItem }: StudySelectorProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { t } = useTranslation(['study']);

  const studiesTree: StudyDataNode[] = useMemo(
    () =>
      isLoading
        ? []
        : studies.map((study) => ({
            title: study.name,
            key: study.id,
            isLeaf: true,
            rawData: study,
            icon: <Beaker />,
          })),
    [studies, isLoading],
  );

  const handleSelectNode: TreeProps<StudyDataNode>['onSelect'] = (_, { node }) => {
    const { isLeaf, rawData } = node;

    if (isLeaf && rawData) {
      onSelectExtraItem(rawData);
    }
  };

  return (
    <MenuSelector
      treeData={studiesTree}
      selectedKeys={globalStudy ? [globalStudy.id] : []}
      onSelect={handleSelectNode}
      title={globalStudy?.name ?? ''}
      category="Study "
      noDataText={t('selector.noStudies')}
      isLoading={isLoading}
      Icon={StudySwitcherIcon}
    />
  );
};

interface StudyDataNode extends TreeDataNode {
  rawData?: StudyResponse;
}

interface StudySelectorProps {
  studies: StudyListResponse;
  isLoading: boolean;
  onSelectExtraItem: (val: StudyResponse) => void;
}
