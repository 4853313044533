import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const PackageObjects: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <circle cx="4" cy="6" r="1" fill="currentColor" />
    <rect x="7" y="5.25" width="14" height="1.5" rx="0.75" fill="currentColor" />
    <circle cx="4" cy="12" r="1" fill="currentColor" />
    <rect x="7" y="11.25" width="14" height="1.5" rx="0.75" fill="currentColor" />
    <circle cx="4" cy="18" r="1" fill="currentColor" />
    <rect x="7" y="17.25" width="14" height="1.5" rx="0.75" fill="currentColor" />
  </BaseIcon>
));
