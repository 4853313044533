import { PageTemplateSimple } from '@components';
import { CrossStudyDetails } from '@modules/study/components';
import { useTranslation } from 'react-i18next';

const crossStudyDetails = {
  id: 0,
  name: 'Global Study',
  summary: 'Global Study allows user to view and fill Reference Tables which can be used in any Study',
};

export const CrossStudyPage = () => {
  return (
    <PageTemplateSimple content={{ isLoading: false }}>
      <CrossStudyDetails study={crossStudyDetails} />
    </PageTemplateSimple>
  );
};
