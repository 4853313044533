import { selectGlobalStudy } from '@app/duck/appSelectors';
import { useAppPermissions, useFeatures, useStudyPermissions } from '@modules/user/duck/userHooks';
import { crossStudyMenu, MenuType, studyMenu } from '@routes/appMenu';
import { MainMenu } from '@components';
import { useStudyListQuery } from '@modules/study/duck/studyApi';
import { StudyListResponse, StudyResponse } from '@modules/study/StudyTypes';
import routes from '@routes';
import { getMatchedResolver } from '@shared/utils/RoutesUtils';
import RoutesStudy from '@routes/study/RoutesStudy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import { useLocation, useNavigate } from 'react-router-dom';
import { StudySelector } from './StudySelector';

export const StudyMenu = ({ isCrossStudy }: IStudyMenuProps) => {
  const globalStudy = useSelector(selectGlobalStudy);
  const { userPermissions, userPermissionsReady } = useStudyPermissions();
  const { appPermissions } = useAppPermissions();
  const features = useFeatures();
  const location = useLocation();
  const navigate = useNavigate();

  const studiesQuery = useStudyListQuery(undefined, { skip: isCrossStudy });
  const studies = sortBy(studiesQuery.data, 'name');

  const menuItems = useMemo(() => {
    let items: MenuType = [];

    if (isCrossStudy) {
      return crossStudyMenu(appPermissions, features);
    }

    if (globalStudy?.id && userPermissionsReady) {
      items = studyMenu(globalStudy.id, userPermissions, features);
    }

    return items;
  }, [isCrossStudy, globalStudy?.id, userPermissionsReady, appPermissions, features, userPermissions]);

  const onSelectExtraItem = (rawData: StudyResponse) => {
    const currentPath = location.pathname;
    const resolver = getMatchedResolver(currentPath, RoutesStudy);

    const stateData = {
      protocolId: rawData.protocol,
      name: rawData.name,
      studySchema: rawData.study_schema,
      configured: rawData.configured,
    };

    if (resolver) {
      navigate(resolver({ studyId: rawData.id }), {
        state: stateData,
      });
    } else {
      navigate(routes.study.view.resolver({ studyId: rawData.id }), {
        state: stateData,
      });
    }
  };

  return (
    <MainMenu
      items={menuItems}
      isLoading={isCrossStudy ? false : !globalStudy?.id || !userPermissionsReady}
      extraItems={isCrossStudy ? null : (studies as StudyListResponse)}
      onSelectExtraItem={onSelectExtraItem}
    >
      {!isCrossStudy && (
        <StudySelector studies={studies} isLoading={studiesQuery.isLoading} onSelectExtraItem={onSelectExtraItem} />
      )}
    </MainMenu>
  );
};

interface IStudyMenuProps {
  isCrossStudy?: boolean;
}
