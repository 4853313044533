import { DraggableModal, Table, Tag } from '@ui';
import { Domain, JobHistory, Status } from '@modules/job/JobTypes';
import { useJobHistoryQuery } from '@modules/job/duck/jobApi';
import { dateToString } from '@shared/utils/Date';
import { ActorAndInfo } from '@components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { TableColumnsType } from 'antd';
import { useMemo } from 'react';

const JobModalsHistoryContent = ({ data, t }: JobModalsHistoryContentProps) => {
  const queryJobHistory = useJobHistoryQuery(data.id);

  const columns: TableColumnsType<JobHistory> = useMemo(
    () => [
      {
        width: 200,
        title: t('historyTable.runBy'),
        dataIndex: 'run_at',
        customKey: 'narrow',
        render: (run_at: number, record) => <ActorAndInfo info={dateToString(run_at)} actor={record.run_by} />,
      },
      {
        width: 150,
        title: t('historyTable.status'),
        dataIndex: 'status',
        render: (status: Status) => <Tag color={status}>{status}</Tag>,
      },
      {
        title: t('historyTable.message'),
        dataIndex: 'message',
        render: (message?: string) => message ?? '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Table
      loading={queryJobHistory.isLoading}
      columns={columns}
      dataSource={queryJobHistory.data}
      rowKey={(item) => item.id}
      tableLayout="fixed"
      pagination={false}
    />
  );
};
export const JobModalsHistory = ({ open, data, onClose }: JobModalsHistoryProps) => {
  const { t } = useTranslation(['job']);
  return (
    <DraggableModal
      open={open}
      onCancel={onClose}
      title={t('historyTable.title', { name: data?.table_name })}
      width={890}
      footer={null}
      destroyOnClose
    >
      {open && <JobModalsHistoryContent data={data} t={t} />}
    </DraggableModal>
  );
};

export interface JobModalsHistoryProps {
  open: boolean;
  data: Domain;
  onClose: () => void;
}

interface JobModalsHistoryContentProps extends Pick<JobModalsHistoryProps, 'data'> {
  t: TFunction;
}
