import {
  useDatasetStackUsersQuery,
  useDeleteBulkDatasetMutation,
  useStackDatasetListPaginatedQuery,
} from '@modules/dataset/duck/datasetApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { ButtonWithConfirmation, PageTemplateSimple } from '@components';
import { DatasetConfirmationModalContent, DatasetList } from '@modules/dataset/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { SYSTEM_STORE_NAMES } from '@modules/stores/duck/storeConstants';
import { Trash } from '@components/icons';
import { QueryErrorType } from '@shared/utils/Error';
import { notification } from '@ui';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const StackDatasetsRootPage = () => {
  const { t } = useTranslation('dataset');
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IDataset>(initialPage);
  const [deleteBulkDataset] = useDeleteBulkDatasetMutation();

  const globalStudy = useSelector(selectGlobalStudy);
  const datasetQuery = useStackDatasetListPaginatedQuery({
    study_id: globalStudy?.id!,
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });
  const datasetStudyUsers = useDatasetStackUsersQuery();

  const [selectedTableItems, setSelectedTableItems] = useState<IDataset[]>([]);

  const pagination = getPagination(datasetQuery.data?.totalItems);

  const onHandleBulkDelete = async () => {
    const selectedItems = selectedTableItems.map((el) => el.id);

    if (selectedItems) {
      try {
        await deleteBulkDataset({ ids: selectedItems }).unwrap();
        setSelectedTableItems([]);
        notification.success({
          message: t('rootTable.actions.delete.successBulkDeleteMessage'),
        });
      } catch (error) {
        notification.error({
          message: t('shared.errors.smthWrong'),
          description: (error as QueryErrorType).data.userMsg,
        });
      }
    }
  };

  const hasSelected = selectedTableItems.length > 0;

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('stackPageRootName'),
        secondaryTitle: hasSelected ? `(Selected ${selectedTableItems.length} item(s))` : null,
        refetch: datasetQuery.refetch,
        extra: hasSelected && (
          <ButtonWithConfirmation
            height={300}
            btnText={t('rootTable.bulkDelete')}
            icon={<Trash />}
            danger
            size="large"
            submitFunc={onHandleBulkDelete}
            confirmTitle={t('rootTable.actions.delete.confirmationBulkDeleteTitle')}
            confirmContent={<DatasetConfirmationModalContent selectedTableItems={selectedTableItems} t={t} />}
          />
        ),
      }}
      content={{
        isLoading: datasetQuery.isLoading && !datasetQuery.data,
        errorText: t('stackLoadingError'),
        error: datasetQuery.error,
      }}
    >
      {datasetQuery.data && (
        <DatasetList
          isStackType
          data={datasetQuery.data.items}
          onChange={onTableChange}
          pagination={pagination}
          loading={datasetQuery.isFetching}
          studyId={globalStudy?.id!}
          viewDatasetFolder={SYSTEM_STORE_NAMES.GLOBAL_STACK_DATA}
          t={t}
          selectedTableItems={selectedTableItems}
          setSelectedTableItems={setSelectedTableItems}
          usersFilterList={datasetStudyUsers.data}
        />
      )}
    </PageTemplateSimple>
  );
};
