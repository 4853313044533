import { IAnalysisObjectSQLLabModalsImportProps } from './modals/AnalysisObjectSQLLabModalsImport';
import { IAnalysisObjectCodeLabModalsImportProps } from './modals/AnalysisObjectCodeLabModalsImport';
import { IAnalysisObjectStackDatasetModalsImportProps } from './modals/AnalysisObjectStackDatasetModalsImport';

export enum EAnalysisObjectModalsType {
  'importSQLDataset' = 'importSQLDataset',
  'importCodeLab' = 'importCodeLab',
  'importStackDataset' = 'importStackDataset',
}

export type TAnalysisObjectModalsPushModalPayload =
  | {
      type: EAnalysisObjectModalsType.importSQLDataset;
      data?: IAnalysisObjectSQLLabModalsImportProps['data'];
    }
  | {
      type: EAnalysisObjectModalsType.importCodeLab;
      data?: IAnalysisObjectCodeLabModalsImportProps['data'];
    }
  | {
      type: EAnalysisObjectModalsType.importStackDataset;
      data?: IAnalysisObjectStackDatasetModalsImportProps['data'];
    };

export type TAnalysisObjectModalsState = {
  selectedModal: EAnalysisObjectModalsType | null;
  data?:
    | IAnalysisObjectSQLLabModalsImportProps['data']
    | IAnalysisObjectCodeLabModalsImportProps['data']
    | IAnalysisObjectStackDatasetModalsImportProps['data'];
};
