import { IBaseColumnProps } from '@shared/components/ObjectTable';
import { QueryErrorType } from '@shared/utils/Error';

export enum SNAPSHOT_STATUS {
  'COMPLETED' = 'COMPLETED',
  'FAILED' = 'FAILED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'NEW' = 'NEW',
  'PARTIAL' = 'PARTIAL',
  'COMPLETED_CH' = 'COMPLETED_CH',
}

export interface ISnapshot {
  id: string;
  studyId: number;
  created_by: string;
  protocolId: string;
  description: string;
  createdAt: string;
  cutoffDate?: string;
  subStudies?: string[];
  tablesDetails: Array<{
    id: string;
    tableName: string;
    status: SNAPSHOT_STATUS;
    lastUpdateDate: string;
    errorMessage?: string;
    userFriendlyErrorMessage?: string;
  }>;
}

export interface ISnapshotTable extends IBaseColumnProps {
  category: string;
}

export interface ISnapshotSaveQuery {
  description: string;
  tables?: string[];
  cutoffDate?: string;
}

export interface ISnapshotRetryQuery {
  description: string;
}

export interface CDMQueryErrorType extends QueryErrorType {
  status?: number;
  data: {
    userMsg?: string;
    devMsg?: string;
    rawData?: {
      error: string;
      message?: string;
    };
  };
}

export interface ISnapshotTableErrorQuery {
  snapshotId: string;
  snapshotTableId: string;
}

export interface ISnapshotTableErrorResponse {
  errorMessage: string;
  snapshotId: string;
  tableSnapshotId: string;
  userFriendlyError: string;
}
