import { ActionButton, ContainerStage } from '@modules/modelEditor/components/builder/components';
import { ModelEditorStageActionType } from '@modules/modelEditor/ModelEditorTypes';
import { selectModelEditorErrors } from '@modules/modelEditor/duck/modelEditorSelectors';
import { modelEditorActions } from '@modules/modelEditor/duck/modelEditorSlice';
import { modelActions } from '@modules/model/duck/modelSlice';
import { Badge, Space, Tooltip, Typography } from '@ui';
import { Eye, SettingIcon } from '@components/icons';
import { NodeProps } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import { css, CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export const BaseStage = ({
  customStyle,
  showLabel = true,
  isDeletable = true,
  targetHandle = true,
  sourceHandle = true,
  doubleClickPreview = false,
  disableDataViewer,
  ...props
}: BaseStageProps) => {
  const { t } = useTranslation('model');
  const dispatch = useDispatch();
  const { id, data, isConnectable, selected, actions = {} } = props;
  const { icon, background, name } = data;
  const { onSettings, customActions = [] } = actions;
  const errors = useSelector(selectModelEditorErrors)
    .filter((error) => error.node?.id === id)
    .map((error) => t(error.message, error.params ?? {}));
  const uniqueErrors = Array.from(new Set(errors));
  const actionButtons = [];

  const onDelete = (nodeId: string) => dispatch(modelEditorActions.onDeleteNode(nodeId));

  const onViewData = () => dispatch(modelActions.setDataViewer({ tableKey: data.tableName, tableName: data.name }));

  if (!disableDataViewer) {
    actionButtons.push({
      value: ModelEditorStageActionType.show,
      onClick: onViewData,
      icon: <Eye />,
      title: t('builder.controls.view'),
    });
  }

  if (onSettings) {
    actionButtons.push({
      value: ModelEditorStageActionType.settings,
      onClick: onSettings,
      icon: <SettingIcon />,
      title: t('builder.controls.settings'),
    });
  }

  const onDoubleClick = () => (doubleClickPreview ? onViewData() : onSettings && onSettings());

  return (
    <ContainerStage
      key={id}
      id={id}
      data={data}
      customStyle={[customStyle, cssError(errors.length > 0)]}
      isConnectable={isConnectable}
      selected={selected}
      background={background}
      actionButtons={[...actionButtons, ...customActions]}
      onDeleteStage={onDelete}
      isDeletable={isDeletable}
      targetHandle={targetHandle}
      sourceHandle={sourceHandle}
      onDoubleClick={onDoubleClick}
    >
      {errors.length > 0 && (
        <Tooltip
          color="red"
          title={
            <ul>
              {uniqueErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          }
        >
          <div css={cssBadge}>
            <Badge count={uniqueErrors.length} title="" />
          </div>
        </Tooltip>
      )}
      {icon && (
        <Space>
          <div css={cssIcon}>{icon}</div>
          {showLabel && <Typography.Text css={cssTitle} children={name} />}
        </Space>
      )}

      {!icon && showLabel && (
        <Typography.Title level={4} ellipsis={{ tooltip: { title: name, placement: 'bottom' } }}>
          {name}
        </Typography.Title>
      )}
    </ContainerStage>
  );
};

const cssError = (isError: boolean): CSSObject => ({
  border: isError ? '2px solid red' : '',
});

const cssBadge = (): CSSObject => ({
  position: 'absolute',
  top: 0,
  left: '100%',
  transform: 'translate(-50%, -50%)',
  cursor: 'help',

  '&& .ant-badge-count': {
    fontSize: 20,
    width: 25,
    height: 25,
    borderRadius: 25,
    alignContent: 'center',
  },
});

const cssIcon = (): CSSObject => ({
  '& > svg': {
    height: 32,
    width: 32,
  },
});

const cssTitle = css({
  fontSize: 24,
});

interface Actions {
  onView?: () => void;
  onSettings?: () => void;
  customActions?: ActionButton[];
}

interface BaseStageProps extends NodeProps {
  showLabel?: boolean;
  targetHandle?: boolean;
  sourceHandle?: boolean;
  isDeletable?: boolean;
  actions?: Actions;
  customStyle?: any;
  disableDataViewer?: boolean;
  doubleClickPreview?: boolean;
}
