import {
  Button,
  Card,
  Checkbox,
  Form,
  FormItem,
  FormLayout,
  Input,
  InputNumber,
  Select,
  Space,
  notification,
  Tooltip,
  Typography,
  Alert,
  Divider,
} from '@ui';
import { CloudUpload, CsvUploadIcon } from '@components/icons';
import {
  JobModalsUploadJobContentProps,
  JobUploadFormValues,
  Progress,
  TRequest,
} from '@modules/job/modals/components';
import { DiscoveryJobParams, ProcessParams, useUploadFileMutation } from '@modules/job/duck/jobApi';
import { useJobModalUploadContext } from '@modules/job/modals/components/upload/JobModalProvider';
import { useManualUploadStores } from '@modules/stores/duck/storeHooks';
import { useLazyCrossStudyRTListQuery, useLazyStudyRTListQuery } from '@modules/viewer/duck/viewerApi';
import { SYSTEM_STORE_NAMES } from '@modules/stores/duck/storeConstants';
import { isCrossStudy } from '@shared/utils/common';
import { ActorAndInfo, cssSpace } from '@components';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { Col, Row, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { CSSObject, Theme } from '@emotion/react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

// const timeFormatOptions = [
//   { label: 'Basic', value: 'basic' },
//   { label: 'Best effort', value: 'best_effort' },
// ];
// const timeFormatTooltip = (
//   <dl>
//     <dt>Basic</dt>
//     <dd>YYYY-MM-DD HH:MM:SS or YYYY-MM-DD format</dd>
//     <dt>Best efforts</dt>
//     <dd> YYYY-MM-DD HH:MM:SS format and all ISO 8601 date and time formats</dd>
//   </dl>
// );

export const SelectStep = ({
  data,
  onCancel,
  t,
  uploadFile,
  setCurrent,
  setProcessParams,
  increaseProgress,
  setProgressUpload,
  setIsSystemStore,
  studyId,
  registerRequest,
  setDiscoveryParams,
  runDiscoveryJob,
}: StepOneProps) => {
  const {
    userPermissions: { canRefTableUpload },
  } = useStudyPermissions();

  const uploadRef = useRef<any>(null);

  const globalStudy = useSelector(selectGlobalStudy);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const globalStudyId = globalStudy?.id!;

  const { loading, manualUploadStores } = useManualUploadStores(
    data?.data_store_id,
    {
      canRefTableUpload: !isCrossStudy(globalStudyId) ? canRefTableUpload : true,
    },
    globalStudyId,
  );
  const [lazyStudyRTListQuery] = useLazyStudyRTListQuery();
  const [lazyCrossStudyRTListQuery] = useLazyCrossStudyRTListQuery();

  const { setIsFileProcessingFinished } = useJobModalUploadContext();

  useEffect(() => {
    if (!loading && !manualUploadStores.length) {
      form.setFields([{ name: 'store_id', errors: [t('shared.errors.emptyManualUploadStoreList')] }]);
    } else {
      form.setFields([{ name: 'store_id', errors: [] }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualUploadStores, loading]);

  useEffect(() => {
    if (manualUploadStores) {
      form.resetFields(['store_id']);
    }
  }, [manualUploadStores]);

  const initValues = {
    skipBlankRows: true,
    skipHashOfRows: 0,
    separator: ',',
    // timeFormat: timeFormatOptions[0].value,
  };

  const layout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const updateUploadProgress = (percents: number, estimated: number) =>
    setProgressUpload((prev: any) => ({ ...prev, progress: Math.floor(percents), estimated }));

  const onSubmitDiscover = async (values: JobUploadFormValues) => {
    setIsFileProcessingFinished(false);
    const selectedStore = manualUploadStores.find((item) => item.id === values['store_id']);
    const isRefTable =
      selectedStore?.system &&
      [SYSTEM_STORE_NAMES.REFERENCE_TABLE, SYSTEM_STORE_NAMES.GLOBAL_REFERENCE_TABLE].includes(selectedStore.name);
    if (isRefTable) {
      try {
        const response = isCrossStudy(studyId)
          ? await lazyCrossStudyRTListQuery({ studyId })
          : await lazyStudyRTListQuery({ studyId });

        if (response.data && !response.data.length) {
          notification.warning({
            message: t('uploadRT.errors.warningNoRT'),
          });
          setIsFileProcessingFinished(true);
          return;
        }
      } catch (e) {
        console.error(e);
        setIsFileProcessingFinished(true);
      }
    }

    setCurrent(1);
    try {
      const uploadRequest = uploadFile({
        file: values.file[0].originFileObj as File,
        callback: updateUploadProgress,
        study_id: studyId,
      });
      registerRequest(uploadRequest);
      const result = await uploadRequest.unwrap();

      setProcessParams({
        filename: result.filename ?? '',
        store_id: values.store_id,
        ignore_errors: values.ignoreErrors,
        offset: values.skipHashOfRows,
        separator: values.separator,
      });

      const data = {
        store_id: values.store_id,
        separator: values.separator,
        filename: result.filename ?? '',
        skip_blank: values.skipBlankRows,
        skip_rows: values.skipHashOfRows,
        ignore_errors: values.ignoreErrors,
        // time_format: values.timeFormat,
        callback: increaseProgress,
        ref_table: isRefTable,
      };

      setDiscoveryParams(data);
      await runDiscoveryJob(data);
    } catch (e: any) {
      setIsFileProcessingFinished(true);
      if (e?.name === 'AbortError') {
        console.warn('Request aborted');
      } else {
        console.error(e);
      }
    }
  };

  const handleStoreChange = (val: any) => {
    const findStore = manualUploadStores.find((item) => item.id === val);
    setIsSystemStore && setIsSystemStore(findStore?.system || false);
  };

  const handleUploadButtonClick = () => {
    uploadRef.current?.upload?.uploader?.fileInput.click();
  };

  return (
    <FormLayout
      {...layout}
      form={form}
      onCancel={onCancel}
      onSubmit={onSubmitDiscover}
      okText={t('upload')}
      initialValues={initValues}
    >
      <Row css={cssFormContainer} gutter={24}>
        <Col span={24}>
          {/*<Row gutter={24}>*/}
          {/*  <Col span={12}>*/}
          {/*    <FormItem name="skipBlankRows" label={t('uploadModal.skipBlankRows')} valuePropName="checked">*/}
          {/*      <Checkbox />*/}
          {/*    </FormItem>*/}
          {/*  </Col>*/}
          {/*  <Col span={12}>*/}
          {/*    <FormItem name="skipHashOfRows" label={t('uploadModal.skipHashOfRows')}>*/}
          {/*      <InputNumber defaultValue={0} min={0} />*/}
          {/*    </FormItem>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row gutter={24}>
            <Col span={16}>
              <FormItem
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
                name="skipHashOfRows"
                label={
                  <Tooltip title={t('uploadModal.skipRowNotice')}>
                    <div css={cssSpace()}>
                      <Typography.Text type="secondary">{t('uploadModal.skipHashOfRows')}</Typography.Text>
                      <QuestionCircleOutlined />
                    </div>
                  </Tooltip>
                }
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={8} style={{ alignContent: 'right' }}>
              <FormItem
                css={{ textAlignLast: 'end' }}
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 2 }}
                name="ignoreErrors"
                label={t('uploadModal.ignoreErrors')}
                valuePropName="checked"
              >
                <Checkbox />
              </FormItem>
            </Col>
            {/*<Col span={12}>*/}
            {/*  <FormItem name="importToStaging" label={t('uploadModal.importToStaging')} valuePropName="checked">*/}
            {/*    <Checkbox />*/}
            {/*  </FormItem>*/}
            {/*</Col>*/}
          </Row>
          <Row gutter={24}>
            {/*<Col span={14}>
          <FormItem
            name="timeFormat"
            label={
              <>
                {t('uploadModal.timeFormat')}
                <Tooltip placement="left" title={timeFormatTooltip}>
                  <QuestionCircleOutlined css={cssTooltipIcon} size={32} />
                </Tooltip>
              </>
            }
          >
            <Select options={timeFormatOptions} placeholder={t('select')} />
          </FormItem>
        </Col> */}
            <Col span={24}>
              <FormItem
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                name="separator"
                label={t('uploadModal.separator')}
                rules={[{ required: true }]}
              >
                <Input />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                name="store_id"
                label={t('uploadModal.targetDataStore')}
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Select
                  options={manualUploadStores}
                  placeholder="Select"
                  loading={loading}
                  fieldNames={{ label: 'name', value: 'id' }}
                  onChange={handleStoreChange}
                />
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FormItem
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                name="file"
                label={t('uploadModal.btnLabel')}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true }]}
              >
                <Dragger
                  ref={uploadRef}
                  name="files"
                  maxCount={1}
                  accept=".csv, .xlsx, .txt"
                  openFileDialogOnClick={false}
                  onChange={onChange}
                  beforeUpload={() => false}
                  fileList={fileList}
                  itemRender={(_, file) => (
                    <Card size="small" key={file.uid} css={cssCard}>
                      <Space css={cssFileLayout} block>
                        <CsvUploadIcon css={{ fontSize: 40 }} />
                        <ActorAndInfo info={file.size ? `${(file.size / 1024).toFixed(2)}Kb` : '-'} actor={file.name} />
                      </Space>
                    </Card>
                  )}
                >
                  <Space direction="vertical" justify="center" css={{ gap: 12, alignItems: 'center' }}>
                    <CloudUpload css={{ fontSize: 32 }} />
                    <Space direction="vertical" css={{ gap: 6 }}>
                      <Typography.Text css={{ fontSize: 18 }}>Drop a file</Typography.Text>
                      <Divider
                        style={{ margin: 0, fontSize: 14, fontWeight: 400, width: 82 }}
                        children="or"
                        variant="solid"
                      />
                    </Space>
                    <Button onClick={handleUploadButtonClick}>{t('uploadModal.btnName')}</Button>
                  </Space>
                </Dragger>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} label={' '} asInfo colon={false}>
                <Alert message={t('uploadModal.selectionStepAlert1')} />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormLayout>
  );
};

const cssFormContainer = (): CSSObject => ({
  flex: 1,
});

const cssUploadContainer = (theme: Theme): CSSObject => ({
  border: `1px dashed ${theme['color-grey-400']}`,
  width: 560,
  height: 200,
  // alignContent: 'center',
});

const cssFileLayout = (): CSSObject => ({
  // '&& div:last-child': {
  //   overflow: 'hidden',
  // },
});

const cssFileNameLabel = (): CSSObject => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const cssCard = (theme: Theme): CSSObject => ({
  marginTop: '1rem',
  backgroundColor: theme['color-grey-200'],
});

interface StepOneProps extends Omit<JobModalsUploadJobContentProps, 'cancelled'> {
  uploadFile: ReturnType<typeof useUploadFileMutation>[0];
  setCurrent: (val: number) => void;
  setProcessParams: (val: ProcessParams) => void;
  increaseProgress: (val?: number | undefined) => void;
  setProgressUpload: (val: Progress) => void;
  setIsSystemStore?: (val: boolean) => void;
  studyId: number;
  registerRequest: (request: TRequest) => void;
  runDiscoveryJob: (val: DiscoveryJobParams) => void;
  setDiscoveryParams: (val: DiscoveryJobParams) => void;
}
