import { TableFiltersConfig, TableSorterConfig } from '@ui';
import { CREATED_BY_SYSTEM } from '@modules/stores/duck/storeConstants';
import { CrossStudyId } from '@config/constants';
import { SEPARATOR_LOCALE } from '@shared/GlobalConstants';
import { capitalizeFirstLetter } from '@shared/utils/Form';

export const initialPage = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: [],
};

export const sleep = (timeMs: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeMs);
  });

export const prepareFilters = (filters: TableFiltersConfig) =>
  Object.fromEntries(
    Object.keys(filters)
      .filter((key) => !!filters[key])
      .map((key) => [key, filters[key]?.join(',')]),
  );

export const sortOrder: Record<string, string> = {
  ascend: 'asc',
  descend: 'desc',
};

export const prepareSorter = <T>(sorter: TableSorterConfig<T>) => {
  const config = Array.isArray(sorter) ? sorter.at(0) : sorter;
  return {
    sort_by: config?.order ? (config?.field as string) : undefined,
    order: sortOrder[config?.order ?? ''],
  };
};

export const getCreatedByInfo = (record: Record<string, any>) =>
  record.system ? CREATED_BY_SYSTEM : record.created_by;

export const getUpdatedByInfo = (record: Record<string, any>) =>
  record.system ? CREATED_BY_SYSTEM : record.updated_by;

export const isCrossStudy = (studyId: number | undefined) => studyId === CrossStudyId;

export const beautifyUsername = (username?: string) => {
  if (username?.includes(':')) {
    return username.split(':')[0];
  }
  return username;
};

export const getNumberWithSeparator = (value: number) => value?.toLocaleString(SEPARATOR_LOCALE);

export const getReportVersionLabel = (versionNumber?: number | null): string => {
  if (typeof versionNumber === 'number') {
    return `V${versionNumber + 1}.0`;
  }

  return 'V1.0';
};

export function clearUndefinedFields<T extends Record<string, any>>(data: T): T {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {} as T);
}

export const normalizeStatus = (status: string) => {
  return capitalizeFirstLetter(status.replace('_', ' '));
};
