import { BaseIcon, SvgIconProps } from '@components/icons/BaseIcon';
import { forwardRef, ForwardRefExoticComponent } from 'react';

export const ExportIcon: ForwardRefExoticComponent<SvgIconProps> = forwardRef((props, ref) => (
  <BaseIcon {...props} forwardRef={ref} width={24} height={24} fill="none" viewBox="0 0 24 24">
    <path
      d="M9 8.25H7.5C6.90326 8.25 6.33097 8.48705 5.90901 8.90901C5.48705 9.33097 5.25 9.90326 5.25 10.5V19.5C5.25 20.0967 5.48705 20.669 5.90901 21.091C6.33097 21.5129 6.90326 21.75 7.5 21.75H16.5C17.0967 21.75 17.669 21.5129 18.091 21.091C18.5129 20.669 18.75 20.0967 18.75 19.5V10.5C18.75 9.90326 18.5129 9.33097 18.091 8.90901C17.669 8.48705 17.0967 8.25 16.5 8.25H15M15 5.25L12 2.25M12 2.25L9 5.25M12 2.25V15"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
));
