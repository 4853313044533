import { Table, Link, CustomColumnsType } from '@ui';
import routes from '@routes';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { ActorAndInfo } from '@components';
import { StudyListResponse, StudyResponse } from '@modules/study/StudyTypes';
import { SELECTED_ACTIONS } from '@shared/GlobalConstants';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { debounce } from 'lodash';

export const StudyList = ({ studies }: StudyListProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [scrollHeight, setScrollHeight] = useState(900);
  const { getColumnSearchProps } = useColumnSearch<StudyListResponse[0]>();
  const [selectedStudies, setSelectedStudies] = useState<React.Key[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<SELECTED_ACTIONS>(SELECTED_ACTIONS.SHOW_ALL);

  useLayoutEffect(() => {
    const onResize = debounce(() => {
      const tableClientRect = bodyRef.current?.getBoundingClientRect();
      setScrollHeight((tableClientRect?.height || 900) - 60); // subtract table head height
    }, 500);

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const columns: CustomColumnsType<StudyResponse> = useMemo(
    () => [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Study Name',
        ...getColumnSearchProps('name'),
        render: (_, record) => (
          <Link
            to={routes.study.view.resolver({ studyId: record.id })}
            state={{
              protocolId: record.protocol,
              name: record.name,
              studySchema: record.study_schema,
              configured: record.configured,
            }}
            children={record.name}
          />
        ),
      },
      {
        key: 'studyCategory',
        dataIndex: 'studyCategory',
        title: 'Category',
      },
      {
        key: 'studyVersion',
        dataIndex: 'studyVersion',
        title: 'App Version',
      },
      {
        key: 'summary',
        dataIndex: 'summary',
        title: 'Summary',
      },
      {
        customKey: 'narrow',
        title: 'Created',
        render: (_, record) => (
          <ActorAndInfo actor={record.userCreated.toString()} info={new Date(record.creationDate).toLocaleString()} />
        ),
      },
    ],
    [],
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: StudyListResponse, info: any) => {
      setSelectedStudies(selectedRowKeys);
    },
    getCheckboxProps: (record: StudyListResponse[0]) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: `selected-${record.id}`,
    }),
  };

  const filteredStudies = useMemo(() => {
    if (selectedFilter === SELECTED_ACTIONS.HIDE_SELECTED) {
      return studies?.filter((study) => !selectedStudies.includes(study.id));
    }
    return studies;
  }, [selectedFilter, studies, selectedStudies]);

  if (!studies) {
    return null;
  }

  return (
    <div css={cssBody} ref={bodyRef}>
      <Table
        columns={columns}
        rowKey="id"
        rowSelection={{
          columnWidth: 48,
          preserveSelectedRowKeys: true,
          type: 'checkbox',
          ...rowSelection,
          selectedRowKeys: selectedStudies,
          selections: [
            Table.SELECTION_INVERT,
            {
              key: SELECTED_ACTIONS.SHOW_ALL,
              text: 'Show all',
              onSelect: () => setSelectedFilter(SELECTED_ACTIONS.SHOW_ALL),
            },
            {
              key: SELECTED_ACTIONS.HIDE_SELECTED,
              text: 'Hide selected',
              onSelect: () => setSelectedFilter(SELECTED_ACTIONS.HIDE_SELECTED),
            },
          ],
        }}
        dataSource={filteredStudies}
        pagination={false}
        virtual
        scroll={{ y: scrollHeight }}
      />
    </div>
  );
};

const cssBody = css`
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
`;

interface StudyListProps {
  studies?: StudyListResponse;
}
