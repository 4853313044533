import { DirectoryTree, DraggableModal, Skeleton, Space, Table } from '@ui';
import { TableIcon } from '@components/icons';
import { useColumnSearch } from '@components/ui/table/tableHooks';
import { useSchemaTablesQuery } from '@modules/study/duck/studyApi';
import { SchemaTable, SchemaTableColumn } from '@modules/study/StudyTypes';
import { QueryErrorType } from '@shared/utils/Error';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';
import { notification, TableColumnsType } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DirectoryTreeProps } from 'antd/es/tree';
import Scrollbars from 'react-custom-scrollbars-2';

const StudyModalsDbSchemaContent = ({ t }: StudyModalsDbSchemaContentProps) => {
  const { getColumnSearchProps, locale } = useColumnSearch<SchemaTableColumn>();
  const [selectedTable, setSelectedTable] = useState<string>();
  const schemaTablesQuery = useSchemaTablesQuery();

  useEffect(() => {
    if (schemaTablesQuery.error) {
      notification.error({ message: (schemaTablesQuery.error as QueryErrorType).data.userMsg });
    }
  }, [schemaTablesQuery.error]);

  const tablesList = useMemo(() => {
    const tables = get(schemaTablesQuery.data, 'tables', [] as SchemaTable[]);
    const views = get(schemaTablesQuery.data, 'views', [] as SchemaTable[]);
    const stackTables = get(schemaTablesQuery.data, 'stack_tables', [] as SchemaTable[]);

    const list = [
      {
        title: t('tables'),
        key: 'tables',
        children: tables.map((table) => ({
          title: table.name,
          key: `tables#${table.name}`,
          isLeaf: true,
          icon: <TableIcon color="darkGrey" />,
        })),
      },
      {
        title: t('views'),
        key: 'views',
        children: views.map((view) => ({
          title: view.name,
          key: `views#${view.name}`,
          isLeaf: true,
          icon: <TableIcon color="darkGrey" />,
        })),
      },
    ];

    if (stackTables.length > 0) {
      list.push({
        title: t('stackTables'),
        key: 'stack_tables',
        children: stackTables.map((table) => ({
          title: table.name,
          key: `stack_tables#${table.name}`,
          isLeaf: true,
          icon: <TableIcon color="darkGrey" />,
        })),
      });
    }

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaTablesQuery.data]);

  const columnsList = useMemo(() => {
    if (selectedTable) {
      const [type, name] = selectedTable.split('#');

      const table = get(schemaTablesQuery.data, type, [] as SchemaTable[]).find((item) => item.name === name);
      if (table) {
        return table.schema.map((column, index) => ({ ...column, rowNumber: index + 1 }));
      }
    }

    return [] as SchemaTableColumn[];
  }, [schemaTablesQuery.data, selectedTable]);

  const columns: TableColumnsType<SchemaTableColumn> = useMemo(
    () => [
      {
        width: 60,
        title: t('dbSchema.rowNumber'),
        dataIndex: 'rowNumber',
      },
      {
        title: t('dbSchema.name'),
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
      },
      {
        width: 300,
        title: t('dbSchema.type'),
        dataIndex: 'type',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    if (info.node.isLeaf) {
      setSelectedTable(info.node.key as string);
    } else {
      setSelectedTable(undefined);
    }
  };

  if (schemaTablesQuery.isLoading || schemaTablesQuery.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Space full align="start" style={{ gap: 24 }}>
      <div css={cssTreeContainer}>
        <DirectoryTree
          showLine
          showIcon
          defaultExpandAll
          treeData={tablesList}
          onSelect={onSelect}
          withSwitcherIcon={false}
        />
      </div>

      <div css={cssTableContainer}>
        <Scrollbars autoHide={false} autoHeight autoHeightMin={500}>
          <Table
            locale={locale}
            columns={columns}
            dataSource={columnsList}
            size="small"
            rowKey={(item) => item.name}
            tableLayout="fixed"
            pagination={false}
          />
        </Scrollbars>
      </div>
    </Space>
  );
};

export const StudyModalsDbSchema = ({ open, onClose }: StudyModalsDbSchemaProps) => {
  const { t } = useTranslation(['study']);

  return (
    <DraggableModal
      css={cssModal}
      width={1212}
      height={650}
      open={open}
      title={t('dbSchema.title')}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {open && <StudyModalsDbSchemaContent onClose={onClose} t={t} />}
    </DraggableModal>
  );
};

const cssModal = () => ({
  '& .ant-modal-content': {
    minWidth: 1212,
  },
});

const cssTreeContainer = (): CSSObject => ({
  width: '278px',
  height: '534px',
  borderRadius: 4,
  border: '1px solid #BABDCC',
  marginRight: 12,
});

const cssTableContainer = (): CSSObject => ({
  width: '850px',
  height: '320px',
});

export interface StudyModalsDbSchemaProps {
  open: boolean;
  onClose: () => void;
}

interface StudyModalsDbSchemaContentProps extends Pick<StudyModalsDbSchemaProps, 'onClose'> {
  t: TFunction;
}
