import { Eye, EyeOff } from '@components/icons';
import AntInput, { PasswordProps, InputRef, InputProps } from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import { forwardRef } from 'react';

const CustomInputPassword = forwardRef<InputRef, PasswordProps>(({ children, ...props }, ref) => {
  return (
    <AntInput.Password
      {...props}
      ref={ref}
      iconRender={(visible) => (visible ? <Eye color="darkGrey" /> : <EyeOff color="darkGrey" />)}
    >
      {children}
    </AntInput.Password>
  );
});

const Input = forwardRef<InputRef, InputProps>((props, ref) => {
  return <AntInput ref={ref} {...props} />;
}) as CustomInputType;

Input.Password = CustomInputPassword;
Input.TextArea = AntInput.TextArea;
Input.Group = AntInput.Group;
Input.Search = AntInput.Search;
Input.OTP = AntInput.OTP;

type CustomInputType = typeof AntInput & {
  Password: React.ForwardRefExoticComponent<PasswordProps & React.RefAttributes<InputRef>>;
  TextArea: typeof AntInput.TextArea;
  Group: typeof AntInput.Group;
  Search: typeof AntInput.Search;
  OTP: typeof AntInput.OTP;
};

export { Input, InputNumber };
