import { PageTemplateSimple } from '@components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage } from '@shared/utils/common';
import {
  IAnalysisObjectStackDatasetDeleteProps,
  IStackDatasetObject,
} from '@modules/dnaObject/StackDataset/AnalysisObjectStackDatasetTypes';
import {
  useAnalysisObjectStackDatasetFiltersQuery,
  useAnalysisObjectStackDatasetListQuery,
  useDeleteAnalysisObjectStackDatasetMutation,
} from '@modules/library/analysisObjects/duck/analysisObjectApi';
import { StackDatasetObjectList } from '@modules/dnaObject/StackDataset/components';
import { analysisObjectActions } from '@modules/library/analysisObjects/duck/analysisObjectSlice';
import { EAnalysisObjectModalsType } from '@modules/library/analysisObjects/AnalysisObjectTypes';
import { QueryErrorType } from '@shared/utils/Error';
import { useAppPermissions } from '@modules/user/duck/userHooks';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { Button, notification } from '@ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const AnalysisObjectStackDatasetRoot = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['analysisObjects']);
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IStackDatasetObject>(initialPage);

  const {
    appPermissions: { canCrossStackDatasetsAoImport, canCrossStackDatasetsAoDelete },
  } = useAppPermissions();

  const supportedEnvsQuery = useSupportedEnvsQuery();
  const stackDatasetFilters = useAnalysisObjectStackDatasetFiltersQuery();

  const dataStackDatasetListQuery = useAnalysisObjectStackDatasetListQuery({
    detailed: '1',
    page: paginationState.current - 1,
    page_size: paginationState.pageSize,
    ...preparedFilters,
    ...preparedSorter,
  });

  const [deleteStackDataset, deleteStackDatasetQuery] = useDeleteAnalysisObjectStackDatasetMutation();

  const pagination = getPagination(dataStackDatasetListQuery.data?.totalItems);

  const onDeleteStackDataset = async (value: IAnalysisObjectStackDatasetDeleteProps) => {
    const { id, name } = value;
    try {
      await deleteStackDataset(id).unwrap();
      notification.success({ message: t('stackDataset.modals.deleteSuccess', { name }) });
    } catch (e) {
      console.error(e);
      notification.error({ message: (e as QueryErrorType).data.userMsg });
    }
  };

  const importStackDatasetAOModal = () =>
    dispatch(analysisObjectActions.pushModal({ type: EAnalysisObjectModalsType.importStackDataset }));

  return (
    <PageTemplateSimple
      content={{
        isLoading: dataStackDatasetListQuery.isLoading && !dataStackDatasetListQuery.data,
        errorText: t('stackDataset.loadingError'),
        error: dataStackDatasetListQuery.error,
      }}
      title={{
        children: t('stackDataset.rootPageName'),
        pageTitle: t('stackDataset.pageTitle'),
        refetch: dataStackDatasetListQuery.refetch,
        extra: canCrossStackDatasetsAoImport && <Button children={t('import')} onClick={importStackDatasetAOModal} />,
      }}
    >
      {dataStackDatasetListQuery.data && (
        <StackDatasetObjectList
          data={dataStackDatasetListQuery.data}
          onChange={onTableChange}
          pagination={pagination}
          loading={dataStackDatasetListQuery.isFetching}
          onDelete={canCrossStackDatasetsAoDelete ? onDeleteStackDataset : undefined}
          loadingDelete={deleteStackDatasetQuery.isLoading}
          supportedEnvs={supportedEnvsQuery.data || {}}
          sources={stackDatasetFilters?.data?.sources ?? []}
          t={t}
        />
      )}
    </PageTemplateSimple>
  );
};
