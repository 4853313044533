import { useColumnSearch } from '@components/ui/table/tableHooks';
import { DatasetScopeList, DatasetScopeListScope } from '@modules/dataset/components';
import { IStackDatasetObject } from '@modules/dnaObject/StackDataset/AnalysisObjectStackDatasetTypes';
import { Scope } from '@modules/dataset/DatasetTypes';
import { TableColumnsType } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useStackDatasetObjectColumns = () => {
  const { getColumnSearchProps, locale } = useColumnSearch<IStackDatasetObject>();
  const [studiesDetailsStackDatasetModal, setStudiesDetailsStackDatasetModal] = useState<DatasetScopeListScope>();
  const { t } = useTranslation(['dnaObject']);

  const stackDatasetColumns: TableColumnsType<IStackDatasetObject> = useMemo(
    () => [
      {
        title: t('stackDataset.rootTable.name'),
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        ...getColumnSearchProps('name'),
      },
      {
        title: t('stackDataset.rootTable.scope'),
        dataIndex: 'object_scope',
        key: 'object_scope',
        render: (object_scope: Scope) => (
          <DatasetScopeList
            currentStudyName={'-'}
            onMoreBtnClick={setStudiesDetailsStackDatasetModal}
            scope={object_scope}
          />
        ),
      },
      {
        width: 100,
        title: t('stackDataset.rootTable.source'),
        key: 'source',
        dataIndex: 'source',
        render: (_, record) => record?.source || '-',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return { stackDatasetColumns, studiesDetailsStackDatasetModal, setStudiesDetailsStackDatasetModal, locale };
};
