import { Tooltip } from '@ui';
import { default as AntButton } from 'antd/es/button';
import { ButtonProps, TooltipProps } from 'antd';
import { CSSObject, Theme, useTheme } from '@emotion/react';

const Button: React.FunctionComponent<ButtonProps & { placement?: TooltipProps['placement'] }> = ({
  title,
  danger,
  icon,
  size,
  children,
  placement,
  ...props
}) => {
  const theme = useTheme();

  const renderButton = () => (
    <AntButton
      css={cssButton(theme, size)}
      {...props}
      size={size ?? (icon ? 'middle' : 'large')}
      danger={danger}
      icon={icon}
      children={children}
    />
  );

  return icon ? (
    <Tooltip placement={placement ?? 'bottom'} title={title}>
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
};

export { Button };

const cssButton = (theme: Theme, size?: ButtonProps['size']): CSSObject => {
  const iconSizeMap: Record<Exclude<ButtonProps['size'], undefined>, number | undefined> = {
    small: theme?.Button?.onlyIconSizeSM,
    middle: theme?.Button?.onlyIconSize,
    large: theme?.Button?.onlyIconSizeLG,
  };

  return {
    '&&': {
      '&&.ant-btn-color-dangerous.ant-btn-variant-outlined': {
        borderColor: theme['color-grey-400'],
      },

      '&&.ant-btn .ant-btn-icon': {
        display: 'flex',
        fontSize: size ? iconSizeMap[size] : 24,
      },

      '&&.ant-btn-icon-only': {
        padding: 4,
      },
    },
  };
};
