import { ViewerDataTable } from '@shared/components';
import { Typography } from '@ui';
import { DataViewerEnvSelector } from '@modules/viewer/components/DataViewerEnvSelector';
import { useLocationResolver } from '@routes/routesHooks';
import { ViewerDataTableRef } from '@shared/components/ViewerDataTable/ViewerDataTableTypes';
import { ITableListSiderProps } from '@shared/components/TableListSider/TableListSiderTypes';
import { AppToolbarPortal } from '@app/components/Header/AppToolbarPortal';
import { AppGatewaySection, FallbackRender } from '@app/components';
import { useStudyPermissions } from '@modules/user/duck/userHooks';
import { usePageTitle } from '@app/duck/appHooks';
import { selectAppliedENVSwitch } from '@app/duck/appSelectors';
import { css, CSSObject } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { JSXElementConstructor, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { NodeId } from 'react-accessible-treeview';
import { viewerActions } from '../duck/viewerSlice';
import { selectViewerLeftSideData } from '../duck/viewerSelectors';
import { DataViewerSnapshotSelector } from '../components/DataViewerSnapshotSelector';

export const ViewerDataPageTemplate = ({ tableListSider: TableListSider }: IViewerDataPageTemplateProps) => {
  const { t } = useTranslation(['viewer']);
  const {
    userPermissions: { canDataViewerRtSwitch, canSnapshotsRead },
  } = useStudyPermissions();
  usePageTitle(t('pageTitle'));
  const { searchParams, pushSearchParams } = useLocationResolver();
  const appliedENVSwitch = useSelector(selectAppliedENVSwitch) || '';

  const dispatch = useDispatch();
  const childRef = useRef<ViewerDataTableRef | null>(null);
  const { tableKey, tableName, tableFolderId } = useSelector(selectViewerLeftSideData);

  const selectDataViewerTable = useCallback((tableKey: string, tableName: string, tableFolderId: NodeId | null) => {
    dispatch(viewerActions.setLeftSideData({ tableKey, tableName, tableFolderId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tableName && tableName !== searchParams.get('tableId')) {
      searchParams.set('tableId', tableName);
      searchParams.set('tableFolderId', tableFolderId?.toString() ?? '');
      searchParams.delete('tableFolderName');
      pushSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName, tableFolderId]);

  useEffect(() => {
    searchParams.delete('tableFolderId');
    pushSearchParams(searchParams);
  }, [appliedENVSwitch]);

  const handleRefetch = useCallback(() => childRef.current && childRef.current.refetch(), []);

  const showTableViewer = tableName && tableKey;
  const defaultTableId = searchParams.get('tableId');
  const defaultFolderName = searchParams.get('tableFolderName');
  const defaultFolderId = searchParams.get('tableFolderId');

  return (
    <div css={cssBody}>
      <AppToolbarPortal>
        <AppGatewaySection onlyStudy>
          {canSnapshotsRead && <DataViewerSnapshotSelector tableName={tableName} />}
        </AppGatewaySection>
        <AppGatewaySection onlyGLMode onlyStudy>
          {canDataViewerRtSwitch && <DataViewerEnvSelector />}
        </AppGatewaySection>
      </AppToolbarPortal>
      <TableListSider
        defaultTableName={defaultTableId}
        defaultFolderName={defaultFolderName}
        defaultFolderId={defaultFolderId}
        selectedTable={tableKey}
        onSelectTable={selectDataViewerTable}
        refetch={handleRefetch}
      />
      <ErrorBoundary FallbackComponent={FallbackRender}>
        <div css={cssContent}>
          {showTableViewer ? (
            <ViewerDataTable
              ref={childRef}
              tableId={tableKey}
              tableName={tableName}
              refetch={handleRefetch}
              showHeader
              showPagination
              showTracedButtons
            />
          ) : (
            <div css={cssEmptyBox}>
              <Typography.Title
                css={cssTableNotFound}
                type="secondary"
                children={
                  defaultTableId ? t('root.tableNotExist', { table: defaultTableId }) : t('root.noSelectedTable')
                }
              />
            </div>
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
};

interface IViewerDataPageTemplateProps {
  tableListSider: JSXElementConstructor<ITableListSiderProps>;
}

const cssBody = (): CSSObject => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const cssContent = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  overflowX: 'hidden',
  overflowY: 'hidden',
});

const cssEmptyBox = (): CSSObject => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const cssTableNotFound = css({
  textAlign: 'center',
  margin: '0 1em',
});
