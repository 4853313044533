import { Space, Typography } from '@ui';
import { RefreshButton } from '@components';
import { ReactNode } from 'react';
import { TitleProps } from 'antd/es/typography/Title';
import { CSSObject } from '@emotion/react';

export const PageTitle = ({ children, extra, hidden, refetch, secondaryTitle, level = 4 }: PageTitleProps) => {
  if (hidden) {
    return null;
  }

  return (
    <Space css={cssTitle(level)} full align="center" justify="space-between">
      <Space full direction="horizontal">
        <Typography.Title level={level}>{children}</Typography.Title>
        {secondaryTitle && <Typography.Text type="secondary">{secondaryTitle}</Typography.Text>}
      </Space>
      {(extra || refetch) && (
        <div css={cssExtra}>
          <Space>
            {refetch && <RefreshButton onClick={refetch} iconOnly />}
            {extra}
          </Space>
        </div>
      )}
    </Space>
  );
};

const cssTitle = (level: TitleProps['level']): CSSObject => ({
  marginBottom: level === 2 ? '16px' : '24px',
});

const cssExtra = () => ({
  marginTop: '0.2em',
});

export interface PageTitleProps {
  children: ReactNode;
  pageTitle?: string;
  extra?: ReactNode;
  hidden?: boolean;
  level?: TitleProps['level'];
  refetch?: () => void;
  secondaryTitle?: string | null;
}
