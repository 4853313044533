import { Space, Typography } from '@ui';
import { IDataset } from '@modules/dataset/DatasetTypes';
import Scrollbars from 'react-custom-scrollbars-2';
import { TFunction } from 'i18next';
import { CSSObject } from '@emotion/react';

export const DatasetConfirmationModalContent = ({ selectedTableItems, t }: DatasetConfirmationModalContentProps) => (
  <Space direction="vertical" full>
    <Typography.Text type="danger">
      {t('rootTable.actions.delete.confirmationBulkDelete', { amount: selectedTableItems.length })}
    </Typography.Text>
    <Scrollbars css={cssLayout} autoHide={false} autoHeight autoHeightMin={300}>
      <pre css={cssSelectedList}>
        {selectedTableItems
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((el) => `${el.name}\n`)}
      </pre>
    </Scrollbars>
  </Space>
);

const cssSelectedList = (): CSSObject => ({
  fontSize: '14px',
  lineHeight: 1.5,
  overflow: 'visible',
  whiteSpace: 'pre-wrap',
  width: '100%',
});

const cssLayout = (): CSSObject => ({
  height: 300,
  marginTop: 20,
});

interface DatasetConfirmationModalContentProps {
  selectedTableItems: IDataset[];
  t: TFunction;
}
