import { GlobeAlt, LibrariesSwitcherIcon } from '@components/icons';
import { MenuSelector } from '@components/MainMenu/components/MenuSelector';
import { Library, LibraryListResponse } from '@modules/library/root/LibraryTypes';
import { selectGlobalLibrary } from '@app/duck/appSelectors';
import { useMemo } from 'react';
import { TreeDataNode, TreeProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const LibrarySelector = ({ libraries, isLoading, onSelectExtraItem }: LibrarySelectorProps) => {
  const { t } = useTranslation(['libraryRoot']);

  const globalLibrary = useSelector(selectGlobalLibrary);

  const librariesTree: LibraryDataNode[] = useMemo(
    () =>
      isLoading
        ? []
        : libraries.map((library) => ({
            title: library.name,
            key: library.id,
            isLeaf: true,
            rawData: library,
            icon: <GlobeAlt />,
          })),
    [libraries, isLoading],
  );

  const handleSelectNode: TreeProps<LibraryDataNode>['onSelect'] = (_, { node }) => {
    const { isLeaf, rawData } = node;
    if (isLeaf && rawData) {
      onSelectExtraItem(rawData);
    }
  };

  return (
    <MenuSelector
      treeData={librariesTree}
      selectedKeys={globalLibrary ? [globalLibrary.id] : []}
      onSelect={handleSelectNode}
      title={globalLibrary?.name ?? ''}
      category={t('selector.category')}
      noDataText={t('selector.noLibraries')}
      isLoading={isLoading}
      Icon={LibrariesSwitcherIcon}
    />
  );
};

interface LibraryDataNode extends TreeDataNode {
  rawData?: Library;
}

interface LibrarySelectorProps {
  libraries: LibraryListResponse['items'];
  isLoading: boolean;
  onSelectExtraItem: (val: Library) => void;
}
