import { Button } from '@ui';
import { TOOLBAR_BUTTON_HEIGHT } from '@modules/modelEditor/components/builder/constants';
import { ModelEditorNodeType } from '@modules/modelEditor/ModelEditorTypes';
import { NodeConfigProps, StageConfig } from '@modules/modelEditor/components/builder/StageConfig';
import { getOnMouseClickCoordinates } from '@modules/modelEditor/components/builder/Utils';
import React from 'react';
import { CSSObject } from '@emotion/react';
import { useReactFlow } from 'reactflow';
import { useTranslation } from 'react-i18next';

export const StageButton = ({ nodeType }: ToolbarButtonProps) => {
  const { t } = useTranslation(['model']);
  const { getNodes } = useReactFlow();
  const { background, icon, show } = StageConfig[nodeType];

  const onDragStart = (event: React.DragEvent, nodeType: string, show: boolean) => {
    if (nodeType && show) {
      if (nodeType === ModelEditorNodeType.result) {
        const nodes = getNodes();
        const isResultExists = nodes.some((node) => node.type === ModelEditorNodeType.result);
        if (isResultExists) {
          event.preventDefault();
        }
      }
      event.dataTransfer.setData('application/reactflow', nodeType);
      event.dataTransfer.setData(
        'application/reactflow/mouse_click_coordinates',
        JSON.stringify(getOnMouseClickCoordinates(event)),
      );

      event.dataTransfer.effectAllowed = 'move';
    } else {
      event.preventDefault();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Button
      css={cssDndnode({ background })}
      onDragStart={(event) => onDragStart(event, nodeType, show)}
      disabled={!show}
      draggable
      icon={icon}
      size="large"
    >
      {t(`${nodeType}.buttonLabel`)}
    </Button>
  );
};

interface ToolbarButtonProps {
  nodeType: string;
}

const cssDndnode = ({ background }: Pick<NodeConfigProps, 'background'>): CSSObject => ({
  height: TOOLBAR_BUTTON_HEIGHT,
  minWidth: 80,
  border: 'none',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'grab',
  background,
  fontWeight: 'initial',

  '&&&:hover': {
    boxShadow: '3px 3px 3px 0px rgba(0,0,0,0.1)',
    background,
  },
});
